import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';

const LeaveCarryForward = ({ show, handleClose, handleSubmit, employees, selectedLeave }) => {
    const [totalDays, setTotalDays] = useState(selectedLeave?.total_days);
    const [leaveForwarded, setLeaveForwarded] = useState(selectedLeave?.leave_forwarded);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [valueChanged, setValueChanged] = useState({
        totalDays: false,
        leaveForwarded: false
    });

    const employeeOptions = employees.map((emp) => {
        const { first_name, middle_name, last_name } = emp
        const name = first_name + ' ' + (middle_name ? middle_name + ' ' : '') + last_name
        return {
            value: emp.id,
            label: name,
        }
    });

    const handleCarryForward = () => {
        handleSubmit({
            totalDays,
            leaveForwarded,
            selectedEmployees,
        });
        handleClose();
    };

    return (
        <Modal show={show}>
            <Modal.Header closeButton>
                <Modal.Title>Carry Forward Employees {selectedLeave?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {/* <div className='d-flex justify-content-between'>
                        <Form.Group className="mb-3">
                            <Form.Label>Total Days</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter total days"
                                value={totalDays}
                                onChange={(e) => {
                                    setTotalDays(e.target.value);
                                    setValueChanged((prev) => ({ ...prev, totalDays: true }))
                                }
                                }
                            />
                            {valueChanged.totalDays ? <span>* Previous data {selectedLeave?.total_days}</span> : <></>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Max Leave Forwarded</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter leave forwarded"
                                value={leaveForwarded}
                                onChange={(e) => {
                                    setLeaveForwarded(e.target.value);
                                    setValueChanged((prev) => ({ ...prev, leaveForwarded: true }))
                                }
                                }
                            />
                            {valueChanged.leaveForwarded ? <span>* Previous data {selectedLeave?.leave_forwarded}</span> : <></>}
                        </Form.Group>
                    </div> */}
                    <Form.Group className="mb-3">
                        <Form.Label>Employees</Form.Label>
                        <Select
                            options={employeeOptions}
                            isMulti
                            value={selectedEmployees}
                            onChange={setSelectedEmployees}
                            placeholder="Select employees"
                            closeMenuOnSelect={false}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" disabled={selectedEmployees.length ? false : true} onClick={handleCarryForward}>
                    Carry Forward
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LeaveCarryForward;
