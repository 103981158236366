import React, { createContext, useContext, useEffect, useState } from 'react';
// import { rolesConfig } from '../Routes/rolesConfig';
import { getUserPermissions } from '../services/permissionServices';
import { companyID } from '../auth';

const RoleContext = createContext();

export const RoleProvider = ({ children, role }) => {

  const [rolesConfig, setRolesConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log('rolesConfigNit : ',rolesConfig);
  
  let role_id = localStorage.getItem("employee_role");
  const company_id =companyID();

  const isSubscriptionExpired = JSON.parse(localStorage.getItem("isSubscriptionExpired"));

  function filterPages(data, filterKey) {
    return {
        Admin: {
            pages: {
                [filterKey]: data.Admin.pages[filterKey]
            }
        }
    };
  }
  
  const permissions = rolesConfig[role];

  console.log(' permissions : ',permissions);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const data = await getUserPermissions(role_id,company_id);

        if(isSubscriptionExpired){

          setRolesConfig(filterPages(data.rolesConfig, "subscriptions-plan"));

        } else {

        setRolesConfig(data.rolesConfig);

        }
        
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch permissions');
        setLoading(false);
      }
    };
    if (company_id && role_id) {
      fetchPermissions();
    }
  }, [role_id]);

  return (
    <RoleContext.Provider value={{ role, permissions,rolesConfig }}>
      {children}
    </RoleContext.Provider>
  );
};

export const useRole = () => useContext(RoleContext);
