import React from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import {
  formatDateInTimezone,
  getCurrentDate,
} from "../../../../utils/formatDateInTimezone";

const ListGoal = ({
  goal,
  mainIndex,
  allContributionLevels,
  toggling,
  validationReviewsErrors,
  handleChange,
  errors,
  reviewer,
  selfGoalReviewCycle,
  goal_review_deadline,
  isReviewed,
}) => {
  const emp_id = localStorage.getItem("employeeID");

  console.log(
    "selfGoalReviewCycle?.deadline >= getCurrentDate() ",
    formatDateInTimezone(selfGoalReviewCycle?.deadline),
    getCurrentDate(),
    formatDateInTimezone(selfGoalReviewCycle?.deadline) <= getCurrentDate()
  );

  return (
    <>
      {reviewer == true &&
        goal?.self_reviewed == 1 &&
        getCurrentDate() > formatDateInTimezone(goal_review_deadline) &&
        isReviewed == 0 && (
          <span className="text-danger">
            {" "}
            *You are unable to review the employee's performance as the review
            deadline has expired. If you have any concerns or need additional
            guidance, please contact the HR department.{" "}
          </span>
        )}
      <Card key={mainIndex} style={{ boxShadow: "none" }}>
        <Card.Body>
          <Row>
            <div
              className=" d-flex  justify-content-between"
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex ">
                <h4>
                  {goal?.mainTitle && <b>1.{mainIndex + 1} - </b>}
                  <span style={{ marginLeft: "4px" }}>{goal?.mainTitle}</span>
                  <span
                    style={{ marginLeft: "15px", color: `#024CAA` }}
                    onClick={() => toggling(goal)}
                  >
                    {goal?.allow_edit == 1 && goal?.employee_id == emp_id && (
                      <i class="fa-solid fa-pencil"></i>
                    )}
                  </span>
                </h4>
              </div>
            </div>
          </Row>
          <Row>
            <div>
              <span
                className="badge "
                style={{ backgroundColor: "#3A7D44", marginRight: "10px" }}
              >
                {goal?.goal_status_name}
              </span>
              <span className="ml-4">{goal?.weightage} % of total score</span>
            </div>
          </Row>
          <Row className="mt-3">
            <div className="description-text-color">
              {goal?.main_description}
            </div>
          </Row>
          <Row className="ml-5 mt-3">
            <div>
              <h4>Sub Goals</h4>
            </div>
            {goal?.subGoals?.length > 0 &&
              goal?.subGoals.map((subgoal, index) => (
                <div className="px-5" key={index}>
                  <div>
                    <h4>
                      {" "}
                      <b>
                        1.{mainIndex + 1}.{index + 1} -{" "}
                      </b>{" "}
                      {subgoal?.subTitle}
                    </h4>
                  </div>
                  <div className="pl-5 description-text-color">
                    {" "}
                    {subgoal?.description}
                  </div>
                </div>
              ))}
          </Row>
          {((reviewer == false &&
            selfGoalReviewCycle?.active == 1 &&
            formatDateInTimezone(selfGoalReviewCycle?.deadline) >=
              getCurrentDate()) ||
            (goal?.self_reviewed == 1 && goal?.employee_id == emp_id)) && (
            <>
              {" "}
              <Row className="mt-3">
                <div>
                  <span>
                    <span className="star-color">*</span>
                    <span className="contribution-color">
                      Contribution Level
                    </span>
                    <i class="fa-solid fa-circle-question circle-question"></i>
                  </span>
                </div>
                <div className="mt-2">
                  <select
                    className="contribution-selection"
                    name="contribution_level"
                    value={goal?.contribution_level}
                    onChange={(e) => handleChange(0, mainIndex, e)}
                    disabled={goal?.hide_field == 1 ? true : false}
                  >
                    <option value="">Select Contribution Level</option>
                    {allContributionLevels.length > 0 &&
                      allContributionLevels.map((level, index) => (
                        <option value={level?.id} key={index}>
                          {level?.name}
                        </option>
                      ))}
                  </select>

                  {errors[mainIndex]?.contribution_level && (
                    <div type="invalid" style={{ color: "red" }}>
                      {errors[mainIndex]?.contribution_level}
                    </div>
                  )}
                </div>
              </Row>
              <Row className="mt-3">
                <div>
                  <span>
                    <span className="star-color">*</span>
                    <span className="contribution-color">
                      Comment Your Review
                    </span>
                  </span>
                </div>
                <div className="mt-2">
                  <textarea
                    className="custom-textarea"
                    name="comment"
                    value={goal?.comment}
                    onChange={(e) => handleChange(0, mainIndex, e)}
                    disabled={goal?.hide_field == 1 ? true : false}
                  ></textarea>

                  {errors[mainIndex]?.comment && (
                    <div type="invalid" style={{ color: "red" }}>
                      {errors[mainIndex]?.comment}
                    </div>
                  )}
                </div>
              </Row>
              {goal?.contribute_by?.length > 0 && (
                <Row className="mt-3">
                  <div>
                    <span>
                      <h5>
                        <i class="fa-solid fa-chevron-down chevron-down"></i>{" "}
                        Contribution from Others
                      </h5>
                    </span>
                  </div>
                  {goal?.contribute_by?.map((item, index) => (
                    <div className="mt-2 d-flex pt-2">
                      <div className="pt-1 " style={{ marginLeft: "15px" }}>
                        <span className="contribution-level-user">
                          {" "}
                          <i class="fa-regular fa-user"></i>
                        </span>
                      </div>
                      <div className="" style={{ paddingLeft: "10px" }}>
                        <h5>
                          {" "}
                          Contribution Level By {item?.first_name}{" "}
                          {item?.middle_name} {item?.last_name}
                        </h5>
                        <span className="description-text-color">
                          {item?.contribution_level_name}
                        </span>
                        <div className="description-text-color pt-1">
                          {item?.comment}
                        </div>
                      </div>
                    </div>
                  ))}
                </Row>
              )}
            </>
          )}

          {((reviewer == true &&
            goal?.self_reviewed == 1 &&
            formatDateInTimezone(goal_review_deadline) >= getCurrentDate()) ||
            isReviewed == 1) && (
            <>
              {" "}
              <Row className="mt-3">
                <div>
                  <span>
                    <span className="star-color">*</span>
                    <span className="contribution-color">
                      Contribution Level
                    </span>
                    <i class="fa-solid fa-circle-question circle-question"></i>
                  </span>
                </div>
                <div className="mt-2">
                  <select
                    className="contribution-selection"
                    name="contribution_level"
                    value={goal?.contribution_level}
                    onChange={(e) => handleChange(0, mainIndex, e)}
                    disabled={goal?.hide_field == 1 ? true : false}
                  >
                    <option value="">Select Contribution Level</option>
                    {allContributionLevels.length > 0 &&
                      allContributionLevels.map((level, index) => (
                        <option value={level?.id} key={index}>
                          {level?.name}
                        </option>
                      ))}
                  </select>

                  {errors[mainIndex]?.contribution_level && (
                    <div type="invalid" style={{ color: "red" }}>
                      {errors[mainIndex]?.contribution_level}
                    </div>
                  )}
                </div>
              </Row>
              <Row className="mt-3">
                <div>
                  <span>
                    <span className="star-color">*</span>
                    <span className="contribution-color">
                      Comment Your Review
                    </span>
                  </span>
                </div>
                <div className="mt-2">
                  <textarea
                    className="custom-textarea"
                    name="comment"
                    value={goal?.comment}
                    onChange={(e) => handleChange(0, mainIndex, e)}
                    disabled={goal?.hide_field == 1 ? true : false}
                  ></textarea>

                  {errors[mainIndex]?.comment && (
                    <div type="invalid" style={{ color: "red" }}>
                      {errors[mainIndex]?.comment}
                    </div>
                  )}
                </div>
              </Row>
              {goal?.contribute_by?.length > 0 && (
                <Row className="mt-3">
                  <div>
                    <span>
                      <h5>
                        <i class="fa-solid fa-chevron-down chevron-down"></i>{" "}
                        Contribution from Others
                      </h5>
                    </span>
                  </div>
                  {goal?.contribute_by?.map((item, index) => (
                    <div className="mt-2 d-flex pt-2">
                      <div className="pt-1 " style={{ marginLeft: "15px" }}>
                        <span className="contribution-level-user">
                          {" "}
                          <i class="fa-regular fa-user"></i>
                        </span>
                      </div>
                      <div className="" style={{ paddingLeft: "10px" }}>
                        <h5>
                          {" "}
                          Contribution Level By {item?.first_name}{" "}
                          {item?.middle_name} {item?.last_name}
                        </h5>
                        <span className="description-text-color">
                          {item?.contribution_level_name}
                        </span>
                        <div className="description-text-color pt-1">
                          {item?.comment}
                        </div>
                      </div>
                    </div>
                  ))}
                </Row>
              )}
            </>
          )}
          <Row className="mt-5">
            {/* Tabs (Goal Details / Achievements) */}
            <Col xs={12} className="d-flex row-bottom-goal-detail">
              <div className="me-3">
                <b>Goal Details</b>
              </div>
              {/* <div>Achievements</div> */}
            </Col>

            {/* Content */}
            <Col xs={12} className="mt-3">
              <Row>
                {/* Left Side */}
                <Col md={6}>
                  <p>
                    <span>Weightage:</span>{" "}
                    <span className="description-text-color">
                      {goal?.weightage}%
                    </span>
                  </p>
                  <p>
                    <span>Due Date:</span>{" "}
                    <span className="description-text-color">
                      {goal?.endDate && formatDateInTimezone(goal?.endDate)}
                    </span>
                  </p>
                </Col>

                <Col md={6}>
                  <p>
                    <span>Start Date:</span>{" "}
                    <span className="description-text-color">
                      {goal?.startDate && formatDateInTimezone(goal?.startDate)}
                    </span>
                  </p>
                  <p>
                    <span>Status:</span>{" "}
                    <span className="text-success">
                      {goal?.goal_status_name}
                    </span>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ListGoal;
