import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import config from "../../../config";
import SalaryDetail from "../../../components/SalaryDetail";
import { getPropertyByKey } from "../../../utils/getPropertyByKey";

const EmployeeAssetsDetail = ({selectedEmployeeDetail, setShowAssetDetail}) => {

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assetId, setAssetId] = useState(null);
  const [assetModalLoading, setAssetModalLoading] = useState(true);
  const [assetData, setAssetData] = useState({});
  const [assetStatus, setAssetStatus] = useState([]);
  const [assetType, setAssetType] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const employeeId = localStorage.getItem("employeeID");

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // setEmployId('');
  };

//   const deleteSalary=()=>{
//     setLoading(true);
//     axios.put(`${config.API_BASE_URL}employee/salary-detail/${assetId}`,{
//       updated_by: employeeId
//     })
//     .then(res=>{
//       console.log(res.data.message);
//       setAssetId(null);
//     })
//     .catch(err=>{
//       console.log(err)
//     })
//     .finally(()=>{
//       setLoading(false)
//       // loader
//     })
//   }

  useEffect(()=>{
    fetchAssetStatus();
    fetchAssetType();
   },[])

  const fetchAssetStatus =()=>{
    axios.get(`${config.API_BASE_URL}assets-status`)
    .then((res)=>{
        setAssetStatus(res.data.data)
    })
    .catch(err=>{
        console.log(err)
    })
  }

  const fetchAssetType =()=>{
    axios.get(`${config.API_BASE_URL}asset-types`)
    .then((res)=>{
        setAssetType(res.data.data)
    })
    .catch(err=>{
        console.log(err)
    })
  }

  const columns = [
    {
      title: "Asset",
      dataIndex: "name",
      key:'name',
      render: (text) => text
    },
    {
      title: "Serial number",
      dataIndex: "serial_number",
      key:'serial_number',
      render: (text) => text
    },
    {
      title: "Assigned_date",
      dataIndex: "assigned_date",
      key: "assigned_date",
      render:(text)=> {
          if(text){
            return  text.split('T')[0]
          }else{
           return '-'
          }
        }
    },
    {
      title: "Policy acknowledged",
      dataIndex: "policy_acknowledged",
      key: "policy_acknowledged",
      render:(text)=> text==1 ? "Yes":"No"
    },
      {
        title: "Employee received",
        dataIndex: "employee_received",
        key: "employee_received",
        render:(text)=> text==1 ? "Yes":"No"
      },
      {
        title: "Return date",
        dataIndex: "return_date",
        key: "return_date",
        render:(text)=> {
            if(text){
              return  text.split('T')[0]
            }else{
             return '-'
            }
        }
      },
      {
        title: "Return received",
        dataIndex: "return_received",
        key: "return_received",
        render:(text,record)=> !record.return_date ? '-' : text==1 ? "Yes":"No"
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render:(text)=> getPropertyByKey(assetStatus,'id',text,'name')
      },
    {
      title: "Action",
      render: (text,record) => (
        <div className="action-menu text-end">
          <div className="d-flex gap-2 mt-2">
            <Link
              className="dropdown-item"
              onClick={(e)=>{
                e.preventDefault();
              }}
            >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                      Edit Asset Detail
                    </Tooltip>
                  }
                >
              <i className="fa fa-pencil m-r-5 text-success" />
              </OverlayTrigger>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
          {/* <EmployeeListFilter /> */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
              <div className="d-flex justify-content-between">
              <div className="flex-grow-1">
                <SearchBox setPaginationValue={setPageSize}/>
                </div>
                <button className="btn btn-text" onClick={()=> setShowAssetDetail(false)}><i className="fa-solid fa-arrow-left" /> Back</button>
                </div>
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={selectedEmployeeDetail}
                  rowKey={(record) => record.id}
                  loading={loading}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: selectedEmployeeDetail.length,
                    showSizeChanger: true,
                    onChange: handlePageChange,
                    pageSizeOptions: ['5', '10', '15']
                  }}
                />
              </div>
            </div>
          </div>
        {/* /Page Content */}
        {/* <AllEmployeeAddPopup /> */}
        {/* <SalaryDetail
        show={showModal}
        handleClose={handleCloseModal}
        loading={assetModalLoading}
        setLoading={setAssetModalLoading}
        assetId={assetId}
        assetData={assetData}
        />
        <DeleteModal Name="Delete Salary" confirmDelete={deleteSalary}/> */}
     
    </div>
  );
};

export default EmployeeAssetsDetail;
