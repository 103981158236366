export function getStartandEndDate(months) {
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const futureDate = new Date(currentDate);
    const targetMonth = futureDate.getMonth() + months;

    futureDate.setMonth(targetMonth);

    if (futureDate.getDate() !== currentDate.getDate()) {
        futureDate.setDate(0);
    }

    return {
        start_date: formatDate(currentDate),
        end_date: formatDate(futureDate),
    };
}
