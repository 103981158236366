import axios from "axios";
import React, { useState, useRef } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import * as XLSX from "xlsx";
import config from "../../config";
import { companyID } from "../../auth";
import Loader from "../Loader";
import AcknowledgmentPopup from "../../utils/AcknowledgmentPopup";

const UploadHoliday = ({ show, setShow, handleDownload, holidayListDownloaded, setHolidayListDownloaded }) => {

    const company_id = companyID();
    const token = localStorage.getItem("token");

    const [file, setFile] = useState(null);
    const [excelData, setExcelData] = useState([]);
    const [preview, setPreview] = useState(false);
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState({
        show: false,
        message: ''
    });
    const [acknowledgmentPopup, setAcknowledgmentPopup] = useState({
        show: false,
        status: null,
        message: null,
        icon: null
    })

    const handleClose = () => {
        setShow(false);
        setPreview(false);
        setExcelData([]);
        setFile(null);
        setHolidayListDownloaded(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    }

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);

        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            const filteredData = jsonData.filter((row, index) => {
                if (index === 0) return true;
                return row[0] && row[1];
            });

            setExcelData(filteredData);
        };
        reader.readAsArrayBuffer(selectedFile);
    };

    const showPreview = () => {
        setPreview(true);
    }

    const closePreview = () => {
        setPreview(false);
    }

    const handleDeleteFile = () => {
        setFile(null);
        setExcelData([]);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setPreview(false)
        setAcknowledgmentPopup({
            show: true,
            status: "success",
            message: "File deleted. You can now upload a new file.",
            icon: 'fa-regular fa-circle-check'
        });
        hideAcknowledgmentPopup(false);
    };

    const hideAcknowledgmentPopup = (closeModal = true) => {
        const resetAcknowledgmentPopupData = () => {
            setAcknowledgmentPopup({
                show: false,
                status: null,
                message: null,
                icon: null
            });
            if (closeModal) {
                handleClose();
            }
        }
        setTimeout(() => resetAcknowledgmentPopupData()
            , 3000);
    };

    const saveHolidays = (file_path) => {
        axios.post(`${config.API_BASE_URL}company/${company_id}/upload-holidays`, {file_path})
            .then((res) => {
                setAcknowledgmentPopup({
                    show: true,
                    status: "success",
                    message: res.data.message,
                    icon: 'fa-regular fa-circle-check'
                });
            })
            .catch((err) => {
                console.error("add holidays", err);
                setAcknowledgmentPopup({
                    show: true,
                    status: "danger",
                    message: "Something went wrong",
                    icon: 'fa-regular fa-circle-xmark'
                })
            })
            .finally(() => {
                hideAcknowledgmentPopup();
            })
    }

    const handleUpload = () => {
        if (file) {
            setLoading(prev => ({ ...prev, show: true, message: `Adding Holidays...` }));
            const holiday_excel = new FormData();
            holiday_excel.append("holiday_excel", file);
            axios.post(`${config.API_BASE_URL}employees/upload-holidays-list`, holiday_excel,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    saveHolidays(res.data.filepath);
                })
                .catch((err) => {
                    console.error(err);
                    setAcknowledgmentPopup({
                        show: true,
                        status: "danger",
                        message: "Something went wrong",
                        icon: 'fa-regular fa-circle-xmark'
                    })
                })
                .finally(() => {
                    setLoading(prev => ({ ...prev, show: false, message: '' }));
                    hideAcknowledgmentPopup();
                })

        }
    };

    return (
        <>
            <Modal show={show} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Manage Holiday List</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between mb-3">
                        <Button variant="success" onClick={handleDownload}>
                            <i class="fa-solid fa-file-arrow-down" /> Download Holidays List
                        </Button>
                    </div>
                    <p className="fst-italic">
                        * Download the holiday list, update it with holidays dates, year, and upload it back. You can preview the uploaded file before finalizing.
                    </p>
                    <Form.Group controlId="formFile" className="mb-1 d-flex align-items-baseline justify-content-between">
                        {file?.name ? <p>
                            <strong>Selected File:</strong> {file.name}
                        </p> : <Form.Control
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            disabled={!!file}
                        />}
                        {file?.name && <div className="d-flex">
                            <Button
                                onClick={preview ? closePreview : showPreview}
                                style={{ backgroundColor: 'transparent', border: 'none' }}
                            >
                                <i className={`fa-${preview ? 'regular' : 'solid'} fa-eye`} style={{ color: 'blue' }} />
                            </Button>
                            <Button
                                variant="danger"
                                onClick={handleDeleteFile}
                                style={{ backgroundColor: 'transparent', border: 'none' }}
                            >
                                <i className="fa-solid fa-trash" style={{ color: 'red' }} />
                            </Button>
                        </div>
                        }

                    </Form.Group>
                    {preview && (
                        <>
                            <div>
                                <h5 className="mb-1">Preview:</h5>
                                {excelData.length > 0 ? (
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {excelData[0].map((col, index) => (
                                                    <th key={index}>{col}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {excelData.slice(1).length ? excelData.slice(1).map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {row.map((cell, cellIndex) => (
                                                        <td key={cellIndex}>{cell}</td>
                                                    ))}
                                                </tr>
                                            )) : <tr style={{ textAlign: 'center' }}><td colspan="3">No Holidays</td></tr>}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <p>No valid data to display.</p>
                                )}
                            </div>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleUpload} disabled={file ? false : true}>
                        Upload File
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
                {acknowledgmentPopup.show ? <AcknowledgmentPopup
                    popupVariant={acknowledgmentPopup.status}
                    popupMessage={acknowledgmentPopup.message}
                    popupIcon={acknowledgmentPopup.icon} /> : <></>}
                {(acknowledgmentPopup.show) && <div className="modal-backdrop fade show"></div>}
                {loading.show && <Loader show={loading.show} loadingMessage={loading.message} />}
            </Modal>
        </>
    );
};

export default UploadHoliday;
