import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import config from "../config";
import axios from "axios";
import { companyID } from "../auth";
import AcknowledgmentPopup from "../utils/AcknowledgmentPopup";
import Loader from "./Loader";
import { format } from "date-fns";

const AssignAssetModel = ({ isModalOpen, setIsModalOpen }) => {
    const assignmentDetail = {
        selectedEmployee: null,
        selectedPolicy: null,
        selectedAssetTypes: [],
        selectedAssets: [],
        selectedDate: null
    };
    const [assetAssignmentDetail, setAssetAssignmentDetail] = useState(assignmentDetail)
    const [assetTypeOptions, setAssetTypeOptions] = useState([]);
    const [assetOptions, setAssetOptions] = useState([]);
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [policyOptions, setPolicyOptions] = useState([]);
    const [acknowledgmentPopup, setAcknowledgmentPopup] = useState({
        show: false,
        status: null,
        message: null,
        icon: null
    })
    const [loading, setLoading] = useState(false);

    const company_id = companyID();
    let token = localStorage.getItem("token");
    const employee_id = localStorage.getItem("employeeID");
    const user = localStorage.getItem("user");
    const parseUSer = JSON.parse(user);
    const depart_id = parseUSer?.department_id;
    const desig_id = parseUSer?.designation_id;

    function getAssetsByType(assetTypes, assets) {
        const assetMap = {};

        const assetTypeMap = assetTypes.reduce((map, type) => {
            map[type.value] = type.value;
            return map;
        }, {});

        assets.forEach((asset) => {
            const typeName = assetTypeMap[asset.asset_type];
            if (typeName) {
                if (!assetMap[typeName]) {
                    assetMap[typeName] = [];
                }
                assetMap[typeName].push({ value: String(asset.id), label: asset.serial_number });
            }
        });

        return assetMap;
    }

    const fetchAssetType = () => {
        axios.get(`${config.API_BASE_URL}asset-types`)
            .then((res) => {
                const type = res?.data?.data?.map(item => ({
                    value: item.id,
                    label: item.name,
                }))
                setAssetTypeOptions(type)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}employees/employeeDetails/${company_id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                const employee = response?.data?.filter((item) => item.id != employee_id).map(item => ({
                    value: item.id,
                    label: item.first_name + ' ' + (item.middle_name ? item.middle_name + ' ' : '') + item.last_name
                }))
                setEmployeeOptions(employee);
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };
        if (company_id) {
            fetchEmployees();
        }
    }, []);

    useEffect(() => {
        const policyData = async (e) => {
            try {
                const res = await axios.get(
                    `${config.API_BASE_URL}hr/policy-data/${company_id}/${depart_id}/${desig_id}?hr=${depart_id}&created_by=${employee_id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const policy = res?.data?.results?.filter((policy) => policy.status === 1).map(item => ({
                    value: item.id,
                    label: item.policy_name
                }));
                setPolicyOptions(policy)
            }
            catch (error) {
                console.log(error);
            }
        }
        if (company_id && depart_id && desig_id && employee_id) {
            policyData();
        }
    }, []);

    const fetchAsset = () => {
        axios.get(`${config.API_BASE_URL}company-assets/${company_id}?status=2`)
            .then((res) => {
                const options = getAssetsByType(assetTypeOptions, res?.data?.data);
                setAssetOptions(options)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchAssetType();
    }, [isModalOpen])

    useEffect(() => {
        if (company_id && assetTypeOptions.length > 0) {
            fetchAsset();
        }
    }, [assetTypeOptions, isModalOpen])

    const handleAssetTypeChange = (selectedOptions) => {
        setAssetAssignmentDetail(prev => ({ ...prev, selectedAssetTypes: selectedOptions || [], selectedAssets: [] })); 
    };

    const handleAssetChange = (selectedOptions) => {
        setAssetAssignmentDetail(prev => ({ ...prev, selectedAssets: selectedOptions || [] }));
    };

    const getFilteredAssetOptions = () => {
        return assetAssignmentDetail.selectedAssetTypes.map((type) => ({
            label: type.label, 
            options: assetOptions[type.value] || [],
        }));
    };

    const closeModel = () => {
        setAssetAssignmentDetail(assignmentDetail);
        setAssetTypeOptions([]);
        setAssetOptions([]);
        setIsModalOpen(false);
    }

    const hideAcknowledgmentPopup = () => {
        const resetAcknowledgmentPopupData = () => {
            setAcknowledgmentPopup({
                show: false,
                status: null,
                message: null,
                icon: null
            });
            closeModel();
        }
        setTimeout(() => resetAcknowledgmentPopupData()
            , 2000);
    };

    const formatDateForSQL = (date) => {
        return format(date, "yyyy-MM-dd");
      };

    const handleAssign = () => {
        const employeeAssetData = {
            "employee_id": assetAssignmentDetail.selectedEmployee.value,
            "asset_id": assetAssignmentDetail.selectedAssets,
            "assigned_date": formatDateForSQL(assetAssignmentDetail.selectedDate),
            "policy": assetAssignmentDetail.selectedPolicy.value,
            "assigned_by": employee_id,
            "created_by": employee_id
        }
        setLoading(true);
        axios.post(`${config.API_BASE_URL}employee-assets`, employeeAssetData)
            .then((res) => {
                setAcknowledgmentPopup({
                    show: true,
                    status: "success",
                    message: res.data.message,
                    icon: 'fa-regular fa-circle-check'
                });
            })
            .catch(err => {
                console.log('/employee-assets :', err)
                setAcknowledgmentPopup({
                    show: true,
                    status: "danger",
                    message: "Something went wrong",
                    icon: 'fa-regular fa-circle-xmark'
                })
            })
            .finally(() => {
                setLoading(false)
                hideAcknowledgmentPopup();
            })
    }

    const assignButtonDisable = () => {
        const { selectedEmployee, selectedPolicy, selectedAssets, selectedDate } = assetAssignmentDetail;
        return (selectedEmployee && selectedPolicy && (selectedAssets.length > 0) && selectedDate);
    }

    return (
        <div
            id="assign_asset"
            className={`modal custom-modal fade ${isModalOpen ? "show d-block" : ""}`}
            role="dialog"
        >
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Assign Asset</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={closeModel}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <div className="mb-4">
                                <div className="selected-items">
                                    <div className="d-flex gap-4">
                                        {assetAssignmentDetail.selectedEmployee && (
                                            <div className="d-flex gap-2">
                                                <strong>Employee:</strong>
                                                <div className="d-flex flex-wrap gap-2">
                                                    <span className="badge bg-info">{assetAssignmentDetail.selectedEmployee.label}</span>
                                                </div>
                                            </div>
                                        )}
                                        {assetAssignmentDetail.selectedPolicy && (
                                            <div className="d-flex gap-2">
                                                <strong>Policy:</strong>
                                                <div className="d-flex flex-wrap gap-2">
                                                    <span className="badge bg-warning">{assetAssignmentDetail.selectedPolicy.label}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        {assetAssignmentDetail.selectedAssetTypes.length > 0 && (
                                            <div className="mt-3 d-flex gap-2">
                                                <strong>Asset Types:</strong>
                                                <div className="d-flex flex-wrap gap-2">
                                                    {assetAssignmentDetail.selectedAssetTypes.map((type) => (
                                                        <span key={type.value} className="badge bg-primary">
                                                            {type.label}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        {assetAssignmentDetail.selectedAssets.length > 0 && (
                                            <div className="mt-3 d-flex gap-2">
                                                <strong>Assets:</strong>
                                                <div className="d-flex flex-wrap gap-2">
                                                    {assetAssignmentDetail.selectedAssets.map((asset) => (
                                                        <span key={asset.value} className="badge bg-secondary">
                                                            {asset.label}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Employee <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            options={employeeOptions}
                                            onChange={(selectedOption) => setAssetAssignmentDetail(prev => ({ ...prev, selectedEmployee: selectedOption }))}
                                            value={assetAssignmentDetail.selectedEmployee}
                                            placeholder="Select an employee"
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Attach Asset Policy <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            options={policyOptions}
                                            onChange={(selectedOption) => setAssetAssignmentDetail(prev => ({ ...prev, selectedPolicy: selectedOption }))}
                                            value={assetAssignmentDetail.selectedPolicy}
                                            placeholder="Select a policy"
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Assign Date <span className="text-danger">*</span>
                                        </label>
                                        <DatePicker
                                            selected={assetAssignmentDetail.selectedDate}
                                            onChange={(date) => setAssetAssignmentDetail(prev => ({ ...prev, selectedDate: date }))}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Asset Type <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            options={assetTypeOptions}
                                            isMulti
                                            onChange={handleAssetTypeChange}
                                            value={assetAssignmentDetail.selectedAssetTypes}
                                            placeholder="Select asset types"
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Asset <span className="text-danger">*</span>
                                        </label>
                                        <Select
                                            options={getFilteredAssetOptions()}
                                            isMulti
                                            onChange={handleAssetChange}
                                            value={assetAssignmentDetail.selectedAssets}
                                            placeholder="Select assets"
                                            isDisabled={assetAssignmentDetail.selectedAssetTypes.length === 0}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="submit-section d-flex justify-content-end">
                                <button
                                    className="btn btn-primary submit-btn"
                                    onClick={handleAssign}
                                    disabled={assignButtonDisable() ? false : true}
                                >
                                    Assign
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {acknowledgmentPopup.show ? <AcknowledgmentPopup
                popupVariant={acknowledgmentPopup.status}
                popupMessage={acknowledgmentPopup.message}
                popupIcon={acknowledgmentPopup.icon} /> : <></>}
            {(acknowledgmentPopup.show) && <div className="modal-backdrop fade show"></div>}
            <Loader show={loading} loadingMessage="" />
        </div>
    );
};

export default AssignAssetModel;