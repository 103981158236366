import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import './Loader.css';

const Loader = ({ show, loadingMessage = "" }) => {
  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered
      className="modal-transparent"
    >
      <Modal.Body className="text-center">
      <div className='d-flex gap-2 align-items-center justify-content-center flex-column'>
      <Spinner animation="border" variant="primary" />
        {loadingMessage && <output className="mt-0 badge bg-secondary fs-6">{loadingMessage}</output>}
      </div>
      </Modal.Body>
    </Modal>
  );
};

export default Loader;
