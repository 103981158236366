import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Tab, Nav, Card } from "react-bootstrap";
import UPIimage from '../../../assets/img/upi.png';
import scanQR from '../../../assets/img/scan QR.png';
import '../../../assets/css/PaymentStep.css'
import RazorpayButton from "../Payment/RazorpayButton";
import { useMediaQuery } from "react-responsive";
import { formatDecimal } from "../../../utils/formatDecimal";
import { companyID } from "../../../auth";

const PaymentStep = ({ selectedPlan, onBack, onPaymentSuccess, isSubscriptionExpired }) => {

  const company_id = companyID();

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1000px)" });

  const [paymentMethod, setPaymentMethod] = useState("debit-card");
  const [currency, setCurrency] = useState("INR"); 
  const [receipt, setReceipt] = useState(`receipt_${Date.now()}`); 
  const [redirectToGateway, setRedirectToGateway] = useState(false);
  const [paymentType, setPaymentType] = useState({
    upi: '',
    card: '',
    qr: ''
  })
  const [UPIid, setUPIid] = useState('');

  const handlePayment = (e) => {
    e.preventDefault();
    onPaymentSuccess();
  };

  useEffect(() => {
    const color = localStorage.getItem('companyColor');

    const style = document.createElement('style');
    style.innerHTML = `
      .nav-link.active {
        color: ${color} !important;
      }`;
    document.head.appendChild(style);
  }, []);

  const showPaymentGateway=(method)=>{
    setRedirectToGateway(true);
    setPaymentType({[method]: true});
  }

  return (
    <div className={isSubscriptionExpired ? "mt-5 pt-3" : "page-wrapper"}>
      <Container fluid className="payment-step-container p-4">
        <Row>
          <Col md={12}>
            <div className="d-flex justify-content-between">
              <h4 className="mb-2">Select Payment Method</h4>
              <p style={{ cursor: 'pointer' }} onClick={onBack}>
                <i className="fa-solid fa-arrow-left" /> Back
              </p>
            </div>
          </Col>
          <Col md={12}>
            <Tab.Container activeKey={paymentMethod}>
              <Nav variant="underline" className="mb-4" >
                <Nav.Item>
                  <Nav.Link
                    eventKey="debit-card"
                    onClick={() => {
                      setPaymentMethod("debit-card");
                      setPaymentType({card: true});
                    }
                  }
                  >
                    Debit Card
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="credit-card"
                    onClick={() => {
                      setPaymentMethod("credit-card")
                      setPaymentType({card: true});
                    }
                  }
                  >
                    Credit Card
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="upi"
                    onClick={() => { 
                      setPaymentMethod("upi");
                      setPaymentType({upi: true});
                      setUPIid('');
                    }
                  }
                  >
                    UPI
                  </Nav.Link>
                </Nav.Item>
                {!isSmallScreen ? <Nav.Item>
                  <Nav.Link
                    eventKey="qr"
                    onClick={() => { 
                      setPaymentMethod("qr");
                      setPaymentType({qr: true});
                    }
                  }
                  >
                    QR Code
                  </Nav.Link>
                </Nav.Item>  : <></>}
              </Nav>
            </Tab.Container>
          </Col>
        </Row>

        <Row>
          <Col md={paymentMethod === "qr" ? 8 : 4}>
            {paymentMethod === "debit-card" && (
              <div>
                <h5 className="mb-3">Pay using Debit Card</h5>
                <Row>
                  <Col md={12} sm={6} lg={10} xs={8} xxl={7} xl={8}>
                    <Card
                      className={`mb-3 border-primary`}
                      style={{ backgroundImage: 'linear-gradient(45deg, #0045c7, #ff2c7d)', borderRadius: '5px', color: 'white' }}
                    >
                      <Card.Body className="d-flex flex-column" style={{ position: 'relative' }}>
                        <img src="https://i.ibb.co/PYss3yv/map.png" style={{ position: 'absolute', opacity: '0.3', width: '100%', height: '100%', top: 0, left: 0, }}></img>
                        <div className="d-flex justify-content-between align-items-center" style={{ zIndex: '1' }}>
                          <h6 style={{ color: 'white' }}>Card holder name</h6>
                          <img
                            src="https://i.ibb.co/WHZ3nRJ/visa.png"
                            width="40px"
                            alt="MasterCard"
                          />
                        </div>
                        <p style={{ zIndex: '1' }}>**** **** **** 1289</p>
                        <small style={{ zIndex: '1' }} >Expiry: 09/25</small>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
            {paymentMethod === "credit-card" && (
              <div>
                <h5 className="mb-3">Pay using Credit Card</h5>
                <Row>
                  <Col md={12} sm={6} lg={10} xs={8} xxl={7} xl={8}>
                    <Card
                      className={`mb-3 border-primary`}
                      style={{ backgroundImage: 'linear-gradient(45deg, #0045c7, #ff2c7d)', borderRadius: '5px', color: 'white' }}
                    >
                      <Card.Body className="d-flex flex-column" style={{ position: 'relative' }}>
                        <img src="https://i.ibb.co/PYss3yv/map.png" style={{ position: 'absolute', opacity: '0.3', width: '100%', height: '100%', top: 0, left: 0, }}></img>
                        <div className="d-flex justify-content-between align-items-center" style={{ zIndex: '1' }}>
                          <h6 style={{ color: 'white' }}>Card holder name</h6>
                          <img
                            src="https://i.ibb.co/WHZ3nRJ/visa.png"
                            width="40px"
                            alt="MasterCard"
                          />
                        </div>
                        <p style={{ zIndex: '1' }}>**** **** **** 1289</p>
                        <small style={{ zIndex: '1' }}>Expiry: 09/25</small>
                      </Card.Body>
                    </Card>
                  </Col>

                </Row>
              </div>
            )}
            {paymentMethod === "upi" && (
              <div>
                <h5>Pay using UPI</h5>
                <div className="text-center">
                  <img
                    src={UPIimage}
                    alt="UPI"
                    className="mb-3"
                    width={200}
                  />
                </div>
              </div>
            )}
            {paymentMethod === "qr" && (
              <div className="text-center">
                <h5>Pay using QR Code</h5>
                <p className="mt-3">Click on generate QR code below to complete your payment.</p>
                <img
                  src={scanQR}
                  width={200}
                  alt="QR Code"
                  className="mb-3"
                  onClick={()=>showPaymentGateway('qr')}
                />
              </div>
            )}
          </Col>

          {paymentMethod === "qr" ? <></> : <Col md={4}>
            <Form>
              {paymentMethod === "upi" && (
                <>
                  <h5>Enter UPI ID</h5>
                  <Form.Group className="mb-3 mt-3">
                    <Form.Control
                      type="text"
                      placeholder="example@upi"
                      required
                      onChange={(e)=> { 
                        setUPIid(e.target.value);
                      }
                    }
                    />
                  </Form.Group>
                  {/* <p className="text-muted" style={{ fontStyle: 'italic' }}>* Open your UPI app to approve the payment.
                  </p> */}
                </>
              )}
              {paymentMethod === "debit-card" && (
                <>
                  <h5>Enter Card Details</h5>
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label>Debit Card Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="1234 5678 9012 3456"
                      required
                      onClick={()=>showPaymentGateway('card')}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Name on Card</Form.Label>
                    <Form.Control type="text" placeholder="Full Name" required onClick={()=>showPaymentGateway('card')}/>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Expiration Date</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="MM/YY"
                          required
                          onClick={()=>showPaymentGateway('card')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>CVV</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="123"
                          required
                          onClick={()=>showPaymentGateway('card')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
              {paymentMethod === "credit-card" && (
                <>
                  <h5>Enter Card Details</h5>
                  <Form.Group className="mb-3 mt-3">
                    <Form.Label>Credit Card Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="1234 5678 9012 3456"
                      required
                      onClick={()=>showPaymentGateway('card')}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Name on Card</Form.Label>
                    <Form.Control type="text" placeholder="Full Name" required onClick={()=>showPaymentGateway('card')}/>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Expiration Date</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="MM/YY"
                          required
                          onClick={()=>showPaymentGateway('card')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>CVV</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="123"
                          required
                          onClick={()=>showPaymentGateway('card')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </Col>}

          <Col md={4}>
            <div className="order-summary bg-light rounded">
              <h5>Order Summary</h5>
              <p className="mt-3">Plan: {selectedPlan.plan_name}: {selectedPlan.duration} months</p>
              <p>Price: {selectedPlan.price.amount}</p>
              <p>Discount: {selectedPlan.price.discount}</p>
              <h6>
                Total: <strong>{formatDecimal(selectedPlan.price.discount) ?(selectedPlan.price.amount * selectedPlan.price.discount).toFixed(2) : selectedPlan.price.amount}</strong>
              </h6>
              <RazorpayButton 
              redirectToGateway={redirectToGateway} 
              setRedirectToGateway={setRedirectToGateway} 
              paymentDetail={{...paymentType, plan_id: selectedPlan.plan_id, duration: selectedPlan.duration, UPIid, company_id}} 
              amount={formatDecimal(selectedPlan.price.discount) ?(selectedPlan.price.amount * selectedPlan.price.discount).toFixed(2) : selectedPlan.price.amount} 
              currency={selectedPlan.price.currency} 
              receipt={receipt} 
              onPaymentSuccess={onPaymentSuccess} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PaymentStep;
