import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css"; 
import "@react-pdf-viewer/default-layout/lib/styles/index.css"; 
import { format } from "date-fns";

const AcknowledgePolicyModal = ({ show, onClose, onSubmit, pdfUrl }) => {
  const [step, setStep] = useState(1); 
  const [assetReceived, setAssetReceived] = useState(null); 
  const [policyAcknowledged, setPolicyAcknowledged] = useState(null); 
  const [policyRead, setPolicyRead] = useState(false); 
  const [timerStarted, setTimerStarted] = useState(false);
  const [receivedDate, setReceivedDate] = useState("");

  useEffect(() => {
    let timer;
    if (timerStarted && !policyRead) {
      timer = setTimeout(() => {
        setPolicyRead(true);
      }, 5000); 
    }
    return () => clearTimeout(timer); 
  }, [timerStarted, policyRead]);

  const handleAssetReceivedChange = (e) => {
    const value = e.target.value === "yes";
    setAssetReceived(value);
    if (!value) setStep(3); 
  };

  useEffect(()=>{
    if(receivedDate &&  assetReceived){
      setStep(2);
      setTimerStarted(true) 
    }
  },[receivedDate, assetReceived])

  const handlePolicyAcknowledgedChange = (e) => {
    setPolicyAcknowledged(e.target.value === "yes");
  };

  const formatDateForSQL = (date) => {
          return format(date, "yyyy-MM-dd");
        };

  const handleFormSubmit = () => {
    if (assetReceived && policyAcknowledged) { 
      const data = {
        employee_received_date:  formatDateForSQL(receivedDate),
        policy_acknowledged: policyAcknowledged,
        employee_received: assetReceived
      }
      onSubmit(data);
    }
    else onClose(); 
  };

  const handleDateChange = (e) => {
    setReceivedDate(e.target.value);
  };

  return (
    <Modal show={show} size={(assetReceived && receivedDate) ? "lg" : "sm"} centered backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Policy Agreement</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === 1 && (
          <Form>
            <Form.Group>
              <Form.Label>Have you received the asset?</Form.Label>
              <Form.Check
                type="radio"
                label="Yes"
                name="assetReceived"
                value="yes"
                onChange={handleAssetReceivedChange}
              />
              <Form.Check
                type="radio"
                label="No"
                name="assetReceived"
                value="no"
                onChange={handleAssetReceivedChange}
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Received Date</Form.Label>
              <Form.Control
                type="date"
                value={receivedDate}
                onChange={handleDateChange}
              />
              {!receivedDate && (
                <small style={{ color: "red" }}>
                 * Please select a date to proceed.
                </small>
              )}
            </Form.Group>
          </Form>
        )}

        {step === 2 && (
          <>
            <div
              style={{
                height: "400px",
                border: "1px solid #ddd" 
              }}
            >
              <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                <Viewer 
                fileUrl={pdfUrl}
                theme={{
                    style: {
                      pageWrapper: {
                        display: "block", 
                      },
                    },
                  }}
                />
              </Worker>
            </div>
            {!policyRead && (
              <p style={{ color: "red", marginTop: "10px" }}>
               * Please read the document. The acknowledgment option will be available in 5 seconds.
              </p>
            )}
            {policyRead && (
              <Form className="m-4">
                <Form.Group>
                  <Form.Label>Do you acknowledge the policy?</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="policyAcknowledged"
                    value="yes"
                    onChange={handlePolicyAcknowledgedChange}
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="policyAcknowledged"
                    value="no"
                    onChange={handlePolicyAcknowledgedChange}
                  />
                </Form.Group>
              </Form>
            )}
          </>
        )}

        {step === 3 && (
          <p>
            {assetReceived === false
              ? "You have indicated that you have not received the asset. Click Submit to close."
              : "Thank you for reviewing the policy."}
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleFormSubmit}
          disabled={
            (step === 2 && (!policyRead || policyAcknowledged === null)) ||
            (step === 1 && assetReceived === null)
          }
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AcknowledgePolicyModal;
