import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import config from "../../../../config";
import Loader from "../../../../components/Loader";
import { set } from "react-hook-form";
import { formatDateInTimezone, getCurrentDate } from "../../../../utils/formatDateInTimezone";
import ListGoal from "./ListGoal";
import createCustomSchema from "./../../../../fieldValidator/fieldValidator";
import "../../../../assets/css/createGoal.css";
import { ErrorBar } from "recharts";
import AcknowledgmentPopup from "./../../../../utils/AcknowledgmentPopup";
import { companyID } from "../../../../auth";

const CreateGoal = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const employeeId = searchParams.get("employee_id");
  const year = searchParams.get("year");

  const emp_id = localStorage.getItem('employeeID');
  const location = useLocation();
  const { hasGoals, reviewer, back ,goal_review_deadline, isReviewed } = location.state || {};

  console.log("Has Goals:", hasGoals, reviewer, back , goal_review_deadline);

  useEffect(() => {
    if (!employeeId && !year) {
      navigate("../goal-list");
    }
  }, []);

  const goalData = {
    mainTitle: null,
    startDate: null,
    endDate: null,
    main_description: null,
    weightage: null,
    comment: null,
    contribution_level: null,
    subGoals: [
      {
        subTitle: null,
        description: null,
      },
    ],
  };

  // const navigate = useNavigate();
  const [formValues, setFormValues] = useState([goalData]);
  const [errors, setErrors] = useState({});
  const [totalGoalError, setTotalGoalError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  console.log("Nitesh", formValues);

  // Validation Schema
  const subGoalSchema = Yup.object({
    subTitle: Yup.string()
      .required("Sub Goal is required")
      .min(3, "At least 3 characters"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "At least 10 characters"),
  });

  const mainGoalSchema = Yup.object({
    mainTitle: Yup.string()
      .required("Main Goal is required")
      .min(5, "At least 5 characters"),
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date().required("End Date is required"),
    // .min( "End Date cannot be before Start Date"),
    main_description: Yup.string()
      .required("Description is required")
      .min(10, "At least 10 characters"),
    weightage: Yup.number()
      .nullable() // Allows null values
      .required("Weightage is required")
      .min(0.01, "Weightage must be at least 0.01%")
      .max(100, "Weightage cannot exceed 100%"),
    subGoals: Yup.array()
      .of(subGoalSchema)
      .min(1, "At least one sub-goal is required"),
      contribution_level: createCustomSchema("dropdown", {
        message: "Please Select a Contribution Level!",
      }).when("$isSubmit", {
        is: false, // When not in submission mode, validation is required
        then: (schema) => schema.required("Please Select a Contribution Level!"),
        otherwise: (schema) => schema.notRequired(),
      }),
      comment: Yup.string()
        .min(10, "At least 10 characters")
        .when("$isSubmit", {
          is: false, // When not in submission mode, validation is required
          then: (schema) => schema.required("Comment is required"),
          otherwise: (schema) => schema.notRequired(),
        }),
  });

  const validationSchema = Yup.array().of(mainGoalSchema);

  // Calculate total weightage
  const totalWeightage = formValues.reduce(
    (acc, goal) => acc + parseFloat(goal?.weightage || 0),
    0
  );
  const remainingWeightage = Math.max(
    0,
    parseFloat((100 - totalWeightage).toFixed(2))
  );

  const handleChange = async (index, mainIndex, e) => {
    setTotalGoalError(null);
    const { name, value } = e.target;

    // Deep copy of formValues for immutability
    const updatedFormValues = [...formValues];

    if (
      [
        "mainTitle",
        "startDate",
        "endDate",
        "main_description",
        "weightage",
        "comment",
        "contribution_level",
      ].includes(name)
    ) {
      // Directly update the value without trimming spaces for live typing
      updatedFormValues[mainIndex][name] = value;
      setFormValues(updatedFormValues);

      // Validate only after user finishes typing (onBlur or form submission)
      try {
        const trimmedValue = value.trim() ? value.trim() : null; // Trim value before validation
        await mainGoalSchema.validateAt(name, {
          ...updatedFormValues[mainIndex],
          [name]: trimmedValue,
        });
        setErrors((prevErrors) => ({
          ...prevErrors,
          [mainIndex]: {
            ...prevErrors[mainIndex],
            [name]: null,
          },
        }));

        if (totalWeightage > 100) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [mainIndex]: {
              ...prevErrors[mainIndex],
              [name]: "Total weightage cannot exceed 100%",
            },
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [mainIndex]: {
              ...prevErrors[mainIndex],
              [name]: null,
            },
          }));
        }
        if (
          name === "startDate" &&
          value > updatedFormValues[mainIndex].endDate
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [mainIndex]: {
              ...prevErrors[mainIndex],
              startDate: "Start date cannot be after the end date",
              endDate: null,
            },
          }));
        } else if (
          name === "endDate" &&
          value < updatedFormValues[mainIndex].startDate
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [mainIndex]: {
              ...prevErrors[mainIndex],
              endDate: "End date cannot be earlier than the start date",
              startDate: null,
            },
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [mainIndex]: {
              ...prevErrors[mainIndex],
              [name]: null,
            },
          }));
        }
      } catch (validationError) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [mainIndex]: {
            ...prevErrors[mainIndex],
            [name]: validationError.message,
          },
        }));
      }
    } else {
      // Handle sub-goal fields
      updatedFormValues[mainIndex].subGoals[index][name] = value;
      setFormValues(updatedFormValues);

      // Validate only after user finishes typing
      try {
        const trimmedValue = value.trim(); // Trim value before validation
        await subGoalSchema.validateAt(name, {
          ...updatedFormValues[mainIndex].subGoals[index],
          [name]: trimmedValue,
        });
        setErrors((prevErrors) => ({
          ...prevErrors,
          [mainIndex]: {
            ...prevErrors[mainIndex],
            subGoals: {
              ...prevErrors[mainIndex]?.subGoals,
              [index]: {
                ...prevErrors[mainIndex]?.subGoals?.[index],
                [name]: null,
              },
            },
          },
        }));
      } catch (validationError) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [mainIndex]: {
            ...prevErrors[mainIndex],
            subGoals: {
              ...prevErrors[mainIndex]?.subGoals,
              [index]: {
                ...prevErrors[mainIndex]?.subGoals?.[index],
                [name]: validationError.message,
              },
            },
          },
        }));
      }
    }
  };

  const handleAddMainGoal = () => {
    setFormValues([
      ...formValues,
      {
        mainTitle: null,
        startDate: null,
        endDate: null,
        main_description: null,
        weightage: null,
        contribution_level: null,
        comment: null,
        subGoals: [
          {
            subTitle: null,
            description: null,
          },
        ],
      },
    ]);
  };

  const handleAddSubGoal = (mainIndex) => {
    const updatedFormValues = [...formValues];
    updatedFormValues[mainIndex].subGoals.push({
      subTitle: null,
      description: null,
    });
    setFormValues(updatedFormValues);
  };

  const handleRemoveMainGoal = async (mainIndex, goal) => {
    console.log("remoced Goal", goal);
    if (goal?.main_goal_id) {
      const response = await axios.post(
        `${config.API_BASE_URL}performance/update-mainGoal-status`,
        goal
      );
      console.log("response", response);
    }
    console.log("remove maingoal", mainIndex);
    const updatedFormValues = [...formValues];
    updatedFormValues.splice(mainIndex, 1);
    setFormValues(updatedFormValues);
  };

  const handleRemoveSubGoal = (mainIndex, subGoalIndex) => {
    // Create a new array to avoid mutating the original state
    const updatedFormValues = [...formValues];

    // Remove the specific subgoal
    updatedFormValues[mainIndex].subGoals.splice(subGoalIndex, 1);

    // Update the state with the modified array
    setFormValues(updatedFormValues);
  };

  const validate = async (filteredFormValues , state) => {
    const validationErrors = {}; // Initialize an empty object to store errors

    try {
      // Perform async validation
      await validationSchema.validate(filteredFormValues, {
        abortEarly: false,
        context: { isSubmit: state },
      });
    } catch (err) {
      err?.inner?.forEach((e) => {
        if (e.path) {
          // Split the path for nested fields
          const pathParts = e.path.split(".");
          let currentLevel = validationErrors;

          pathParts.forEach((part, index) => {
            if (part.startsWith("[") && part.endsWith("]")) {
              const arrayIndex = part.slice(1, -1); // Extract index from "[0]"
              if (!currentLevel[arrayIndex]) {
                currentLevel[arrayIndex] = {}; // Initialize the object
              }
              currentLevel = currentLevel[arrayIndex];
            } else if (part.includes("subGoals")) {
              // Handle subGoals as an array
              if (!currentLevel.subGoals) {
                currentLevel.subGoals = []; // Initialize subGoals array
              }
              const subIndexMatch = part.match(/\d+/); // Extract subGoal index
              if (subIndexMatch) {
                const subIndex = subIndexMatch[0];
                if (!currentLevel.subGoals[subIndex]) {
                  currentLevel.subGoals[subIndex] = {}; // Initialize the subGoal object
                }
                currentLevel = currentLevel.subGoals[subIndex];
              }
            } else {
              // Regular fields
              if (index === pathParts.length - 1) {
                // Set the error message for the last part
                currentLevel[part] = e.message;
              } else {
                if (!currentLevel[part]) {
                  currentLevel[part] = {}; // Initialize the object
                }
                currentLevel = currentLevel[part];
              }
            }
          });
        }
      });
    }

    // Transform subGoals into the desired structure
    Object.keys(validationErrors).forEach((key) => {
      if (Array.isArray(validationErrors[key]?.subGoals)) {
        const formattedSubGoals = {};
        validationErrors[key].subGoals.forEach((subGoal, index) => {
          formattedSubGoals[index] = subGoal;
        });
        validationErrors[key].subGoals = formattedSubGoals;
      }
    });

    setErrors(validationErrors); // Set the errors in state
    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formValues);
 
    const validationErrors = await validate(formValues, true);
    console.log("validationErrors", validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    if (totalWeightage !== 100) {
      setTotalGoalError("Your total goal weightage must be exactly 100%");
      window.scrollTo(0, 0);
      return;
    }

    setIsLoading(true);

    console.log("formValues", formValues);

    try {
      const id = localStorage.getItem("employeeID");
      const data = {
        goals: formValues,
        employee_id: employeeId ? employeeId : id,
        year: year ? year : currentYear,
      };
      console.log("data :", data);
      if (hasGoals == true && employeeId && year) {
        const response = await axios.post(
          `${config.API_BASE_URL}performance/update-goal`,
          data
        );
        console.log(response);
      } else {
        const response = await axios.post(
          `${config.API_BASE_URL}performance/create-goal`,
          data
        );
        console.log(response);
        if (response?.data?.success == true) {
          navigate("../goal-list");
        }
      }
    } catch (err) {
      console.error("Error submitting form:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const [currentYear, setCurrentYear] = useState(null);

  useEffect(() => {
    const date = new Date();
    setCurrentYear(date?.getFullYear());
  }, []);

  //--------------------------------- Edit Goal -----------------------------

  const listAllGaolForEmployee = async (employeeID, year) => {
    setIsLoading(true);
    const ID = localStorage.getItem("employeeID");
    console.log("ID" ,ID);
    const response = await axios.get(
      `${config.API_BASE_URL}performance/goals?employee_id=${employeeID}&year=${year}&reviewer=${ID}`
    );
    console.log(response);
    if (response?.data?.result?.length > 0) {
      console.log("(response?.data?.result", response?.data?.result);
      setIsLoading(false);
      // const transformedData = response?.data?.result.map((goal) => ({
      //   main_goal_id: goal.main_goal_id,
      //   mainTitle: goal.mainTitle,
      //   startDate: goal.startDate,
      //   endDate: goal.endDate,
      //   main_description: goal.main_description,
      //   weightage: goal.weightage,
      //   goal_status_name: goal.goal_status_name,
      //   goal_status_id: goal.goal_status_id,
      //   goal_reviews_id: reviewer== true ? null : goal?.goal_reviews_id || null,
      //   reviewed_by:  reviewer== true ? null : goal?.reviewed_by || null,
      //   contribution_level: reviewer== true ?  "" : goal?.contribution_level_id ? goal?.contribution_level_id : null,
      //   comment: reviewer== true? null : goal?.comment ? goal?.comment : null,
      //   goal_review_updated_at: reviewer== true ? null :  goal?.goal_review_updated_at || null,
      //   contribution:reviewer== true ? null : goal?.contribution_level || null,
      //   goal_review_created_at:  goal?.goal_review_created_at || null,
      //   first_name: goal?.first_name || null,
      //   middle_name: (goal?.middle_name !='null' || goal?.middle_name != null)? goal?.middle_name : null,
      //   last_name: goal?.last_name || null,
      //   self_reviewed:  reviewer  == true ? 0 : goal.self_reviewed ,
      //   allow_edit: goal?.allow_edit || 0,
      //   subGoals: goal.subGoals.map((subGoal) => ({
      //     sub_gaol_id: subGoal.sub_goal_id,
      //     subTitle: subGoal.subTitle,
      //     description: subGoal.description,
      //   })),
      // }));

      // console.log("transformedData", transformedData);
      setFormValues(response?.data?.result);
    } else {
      setIsLoading(false);
      setFormValues([]);
    }
  };

  const [toggleEdit, setToggleEdit] = useState(false);
  const [openIndexing, setOpenIndexing] = useState(null);
  const [allContributionLevels, setAllContributionLevels] = useState([]);
  const toggling = (edit_data) => {
    setOpenIndexing(edit_data?.main_goal_id);
    console.log("edit_data", edit_data);
    setToggleEdit(true);
  };
  const listContributionLevel = async () => {
    const response = await axios.get(
      `${config.API_BASE_URL}performance/contribution-level`
    );
    console.log("contrivution level", response);
    if (response?.data?.result?.length > 0) {
      setAllContributionLevels(response?.data?.result);
    }
  };
  useEffect(() => {
    if (hasGoals == true && employeeId && year) {
      listAllGaolForEmployee(employeeId, year);
      setToggleEdit(false);
      listContributionLevel();
    }
  }, [hasGoals]);
  console.log("searchedFormEmployee", formValues);
  console.log("openIndex", openIndexing);


  const [acknowledgmentPopup, setAcknowledgmentPopup] = useState({
    showAcknowledgmentPopup: false,
    popupVariant: "",
    popupMessage: "",
    popupIcon: "",
  });

  const hideAcknowledgmentPopup = () => {
    const resetAcknowledgmentPopupData = () => {
      setAcknowledgmentPopup({
        showAcknowledgmentPopup: false,
        popupVariant: "",
        popupMessage: "",
        popupIcon: "",
      });
    };
    window.scrollTo(0, 0);
    navigate(`..${back}`);
    setTimeout(() => resetAcknowledgmentPopupData(), 2000);
  };

  const submitReview = async (e) => {
    e.preventDefault();
    const company_id = companyID();
    console.log("formValues reviews", formValues);
    const validationErrors = await validate(formValues, false);
    console.log("validationErrors", validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    const id = localStorage.getItem("employeeID");
    const data = { reviews: formValues, reviewed_by: id, year:year , goal_employee_id : formValues[0]?.employee_id};
    console.log("data", data);
    window.scrollTo(0, 0);
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${config.API_BASE_URL}performance/self-review?reviewer=${reviewer}&company_id=${company_id}`,
        data
      );
      console.log("response slef review", response);
      if (response?.data?.success == true) {
        setAcknowledgmentPopup({
          showAcknowledgmentPopup: true,
          popupVariant: "success",
          popupMessage:
            "Goal Performance Review Request Submitted Successfully!",
          popupIcon: "fa-regular fa-circle-check",
        });
      }
      setIsLoading(false);
      hideAcknowledgmentPopup();
      console.log("response self reviewss", response);
    } catch (err) {
      setAcknowledgmentPopup({
        showAcknowledgmentPopup: true,
        popupVariant: "danger",
        popupMessage: err?.response?.data?.message,
        popupIcon: "fa-regular fa-circle-xmark",
      });
      setIsLoading(false);
      hideAcknowledgmentPopup();
      console.log("err hai bhai", err?.response?.data?.message);
    }
  };

  
  const [selfGoalReviewCycle, setSelfGoalReviewCycle] = useState(null);
  const [goalReviewSubmission, setGoalReviewSubmission] = useState(null);
  const [goalEditSubmission, setGoalEditSubmission] = useState(null);
  const [performanceReview, setPerformanceReview] = useState(null);
  const [createGoal, setCreateGoal] = useState(null);

  
  
  const fetchConfiguration = async() =>{
  
  const company_id = companyID();
  const response = await axios.get(`${config.API_BASE_URL}performance/goal-config?company_id=${company_id}`);
  if(response?.data?.result?.length >0){

    response?.data?.result.forEach((item) => {
      switch (item?.review_type) {
        case 1:
          setSelfGoalReviewCycle(item);
          break;
        case 2:
          setGoalReviewSubmission(item);
          break;
        case 3:
          setGoalEditSubmission(item);
          break;
        case 4:
          setPerformanceReview(item);
          break;
        case 5:
          setCreateGoal(item);
          break;
        default:
          break;
      }
    });
  }
  }
   
  useEffect(()=>{
    fetchConfiguration();
  },[])

  console.log("createGoalcreateGoalcreateGoal" ,createGoal,performanceReview,goalEditSubmission,goalReviewSubmission,selfGoalReviewCycle )

  return (
    <>
      {isLoading && <Loader show={isLoading} />}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={
              reviewer == false
                ? employeeId && year && hasGoals == true
                  ? `View Goal ${year && `For ${year}`}`
                  :  `Create Goal ${
                      year ? year : currentYear && `For ${currentYear}`
                    } `
                : `Performance Assesment  ${currentYear} ${
                    formValues &&
                    `For ${
                      formValues[0]?.goal_employee_first_name && formValues[0]?.goal_employee_first_name
                    } ${
                      formValues[0]?.goal_employee_middle_name == null ||
                      formValues[0]?.goal_employee_middle_name == `null`
                        ? " "
                        : formValues[0]?.goal_employee_middle_name
                    } ${formValues[0]?.goal_employee_last_name && formValues[0]?.goal_employee_last_name}`
                  }`
            }
            title="Goal List"
            subtitle={
              employeeId && year && hasGoals == true
                ? "View Goal"
                : "Create Goal"
            }
            titleLink="goal-list"
            modal="#add_goal"
            name="Add New"
          />

          <div className="d-flex justify-content-between">
            <div>
              <h4>Total Goal Weightage : {totalWeightage?.toFixed(2)}%</h4>
              {totalGoalError && (
                <p style={{ color: "#ff3333" }}>*{totalGoalError}</p>
              )}
            </div>
            <Link to={`..${back}`}>
              <i className="fa-solid fa-arrow-left"></i> Back
            </Link>
          </div>
          <Container
            className="my-2 create-goal-wrapper"
            style={{ padding: "0px" }}
          >
            <Form>
              {(employeeId && year && hasGoals == true
                ? formValues[0]?.main_goal_id
                : formValues.length > 0) &&
                formValues?.map((goal, mainIndex) =>
                  openIndexing == goal?.main_goal_id ? (
                    <Card key={mainIndex} style={{ boxShadow: "none" }}>
                      <Card.Body>
                        <Row>
                          <div
                            className=" d-flex  justify-content-between"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex ">
                              <h4>
                                {" "}
                                {goal?.mainTitle && <b>1.{mainIndex + 1} - </b>}
                                <span style={{ marginLeft: "4px" }}>
                                  {goal?.mainTitle}
                                </span>
                              </h4>
                            </div>

                            <div>
                              {formValues.length !== 1 && (
                                <i
                                  className="fa-solid fa-trash"
                                  style={{ color: "#de2121" }}
                                  onClick={() =>
                                    handleRemoveMainGoal(mainIndex, goal)
                                  }
                                ></i>
                              )}
                            </div>
                          </div>
                        </Row>

                        <Row>
                          <Col md={6}>
                            <Form.Group
                              controlId={`mainTitle-${mainIndex}`}
                              className="mb-3"
                            >
                              <Form.Label>Goal</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter main title"
                                name="mainTitle"
                                value={goal?.mainTitle}
                                onChange={(e) => handleChange(0, mainIndex, e)}
                                isInvalid={errors[mainIndex]?.mainTitle}
                              />
                              {errors[mainIndex]?.mainTitle && (
                                <Form.Control.Feedback type="invalid">
                                  {errors[mainIndex]?.mainTitle}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>

                          <Col md={6}>
                            <Form.Group
                              controlId={`weightage-${mainIndex}`}
                              className="mb-3"
                            >
                              <Form.Label>Weightage (%)</Form.Label>
                              <Form.Control
                                type="number"
                                step="0.01"
                                placeholder="Enter weightage"
                                name="weightage"
                                value={goal.weightage}
                                onChange={(e) => handleChange(0, mainIndex, e)}
                                isInvalid={errors[mainIndex]?.weightage}
                              />
                              {errors[mainIndex]?.weightage && (
                                <Form.Control.Feedback type="invalid">
                                  {errors[mainIndex]?.weightage}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Form.Group
                              controlId={`startDate-${mainIndex}`}
                              className="mb-3"
                            >
                              <Form.Label>Start Date</Form.Label>
                              <Form.Control
                                type="date"
                                name="startDate"
                                value={
                                  goal.startDate
                                    ? formatDateInTimezone(goal.startDate)
                                    : null
                                }
                                onChange={(e) => handleChange(0, mainIndex, e)}
                                min={`${new Date().getFullYear()}-01-01`}
                                max={`${new Date().getFullYear()}-12-31`}
                                isInvalid={errors[mainIndex]?.startDate}
                              />
                              {errors[mainIndex]?.startDate && (
                                <Form.Control.Feedback type="invalid">
                                  {errors[mainIndex]?.startDate}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              controlId={`endDate-${mainIndex}`}
                              className="mb-3"
                            >
                              <Form.Label>End Date</Form.Label>
                              <Form.Control
                                type="date"
                                name="endDate"
                                value={
                                  goal.endDate
                                    ? formatDateInTimezone(goal.endDate)
                                    : null
                                }
                                min={`${new Date().getFullYear()}-01-01`}
                                max={`${new Date().getFullYear()}-12-31`}
                                onChange={(e) => handleChange(0, mainIndex, e)}
                                isInvalid={errors[mainIndex]?.endDate}
                              />
                              {errors[mainIndex]?.endDate && (
                                <Form.Control.Feedback type="invalid">
                                  {errors[mainIndex]?.endDate}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <Form.Group
                              controlId={`main_description-${mainIndex}`}
                              className="mb-3"
                            >
                              <Form.Label>Description</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={2}
                                placeholder="Enter description"
                                name="main_description"
                                value={goal.main_description}
                                onChange={(e) => handleChange(0, mainIndex, e)}
                                isInvalid={errors[mainIndex]?.main_description}
                              />
                              {errors[mainIndex]?.main_description && (
                                <Form.Control.Feedback type="invalid">
                                  {errors[mainIndex]?.main_description}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        {goal.subGoals.map((subgoal, index) => (
                          <Card
                            key={index}
                            style={{
                              boxShadow:
                                "0px 4px 24px 0px rgba(188, 188, 188, 0.2509803922)",
                            }}
                          >
                            <Card.Body>
                              <Row>
                                <div
                                  className=" d-flex  justify-content-between"
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="d-flex ">
                                    <h4>
                                      {" "}
                                      {subgoal?.subTitle && (
                                        <>
                                          <b>
                                            1.{mainIndex + 1}.{index + 1}{" "}
                                          </b>{" "}
                                          <span style={{ marginLeft: "4px" }}>
                                            - {subgoal?.subTitle}
                                          </span>
                                        </>
                                      )}
                                    </h4>
                                  </div>

                                  <div>
                                    {goal?.subGoals?.length !== 1 && (
                                      <i
                                        className="fa-solid fa-trash"
                                        style={{ color: "#de2121" }}
                                        onClick={() =>
                                          handleRemoveSubGoal(mainIndex, index)
                                        }
                                      ></i>
                                    )}
                                  </div>
                                </div>
                              </Row>

                              <Row>
                                <Col md={12}>
                                  <Form.Group
                                    controlId={`subTitle-${index}-${mainIndex}`}
                                    className="mb-3"
                                  >
                                    <Form.Label>Sub Goal</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter sub title"
                                      name="subTitle"
                                      value={subgoal?.subTitle || null}
                                      onChange={(e) =>
                                        handleChange(index, mainIndex, e)
                                      }
                                      isInvalid={
                                        errors[mainIndex]?.subGoals?.[index]
                                          ?.subTitle
                                      }
                                    />
                                    {errors[mainIndex]?.subGoals?.[index]
                                      ?.subTitle && (
                                      <Form.Control.Feedback type="invalid">
                                        {
                                          errors[mainIndex]?.subGoals?.[index]
                                            ?.subTitle
                                        }
                                      </Form.Control.Feedback>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Row>
                                <Col md={12}>
                                  <Form.Group
                                    controlId={`description-${index}-${mainIndex}`}
                                    className="mb-3"
                                  >
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      rows={2}
                                      placeholder="Enter description"
                                      name="description"
                                      value={subgoal?.description || null}
                                      onChange={(e) =>
                                        handleChange(index, mainIndex, e)
                                      }
                                      isInvalid={
                                        errors[mainIndex]?.subGoals?.[index]
                                          ?.description
                                      }
                                    />
                                    {errors[mainIndex]?.subGoals?.[index]
                                      ?.description && (
                                      <Form.Control.Feedback type="invalid">
                                        {
                                          errors[mainIndex]?.subGoals?.[index]
                                            ?.description
                                        }
                                      </Form.Control.Feedback>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                {goal?.subGoals?.length - 1 == index && (
                                  <div className="d-flex justify-content-end">
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        handleAddSubGoal(mainIndex)
                                      }
                                    >
                                      <i class="fa-solid fa-plus"></i> Add Sub
                                      Goals
                                    </Button>
                                  </div>
                                )}
                              </Row>
                            </Card.Body>
                          </Card>
                        ))}

                        <Row>
                          {formValues?.length - 1 == mainIndex && (
                            <div className="d-flex justify-content-between">
                              <Button
                                variant="primary"
                                onClick={handleAddMainGoal}
                                disabled={remainingWeightage <= 0}
                              >
                                <i class="fa-solid fa-plus"></i> Add Main Goals
                              </Button>
                            </div>
                          )}
                        </Row>
                      </Card.Body>
                    </Card>
                  ) : (
                    <ListGoal
                      goal={goal}
                      mainIndex={mainIndex}
                      allContributionLevels={allContributionLevels}
                      toggling={toggling}
                      handleChange={handleChange}
                      reviewer={reviewer}
                      errors={errors}
                      createGoal={createGoal}
                      selfGoalReviewCycle={selfGoalReviewCycle}
                      goal_review_deadline={goal_review_deadline}
                      isReviewed={isReviewed}
                    />
                  )
                )}

              {/* Remaining Weightage */}
              <div className="mb-4">
                <strong>Remaining Weightage: {remainingWeightage}%</strong>
              </div>

              {/* Add More Sub Goals */}
              <div className="d-flex justify-content-end">
                {(( reviewer == false && selfGoalReviewCycle?.active == 1 && formValues[0]?.main_goal_id &&
                   formatDateInTimezone(selfGoalReviewCycle?.deadline) >= getCurrentDate() ||  
                          ( formValues[0]?.self_reviewed == 1 && formValues[0]?.employee_id == emp_id))) && (
                  <Button
                    variant="success"
                    type="submit"
                    disabled={formValues[0]?.hide_field == 1 ? true : false }
                    onClick={submitReview} 
                  >
                    Submit Review
                  </Button>
                )}

{(( reviewer == true && 
  (formValues[0]?.self_reviewed == 1 && 
   formatDateInTimezone(goal_review_deadline) >= getCurrentDate())) || isReviewed == 1) && (
                  <Button
                    variant="success"
                    type="submit"
                    disabled={formValues[0]?.hide_field == 1 ? true : false }
                    onClick={submitReview} // Disable if total weightage exceeds 100
                  >
                    Submit Review
                  </Button>
                )}

                {
                  (formValues[0]?.main_goal_id || ( employeeId == emp_id && formValues[0]?.allow_edit == 1) ) && <Button
                  variant="success"
                  type="submit"
                  style={{ marginLeft: "30px" }}
                  disabled={(formValues[0]?.allow_edit == 1 &&  formValues[0]?.main_goal_id) || !formValues[0]?.main_goal_id ? false : true }
                  onClick={handleSubmit} // Disable if total weightage exceeds 100
                >
                  Update Goal
                </Button>
                }

                {/* {(hasGoals == true &&
                  employeeId &&
                  year &&
                  formValues[0]?.allow_edit == 1) && (
                    <Button
                      variant="success"
                      type="submit"
                      style={{ marginLeft: "30px" }}
                      disabled={remainingWeightage < 0}
                      onClick={handleSubmit} // Disable if total weightage exceeds 100
                    >
                      Update Goal
                    </Button>
                  )} */}
                {hasGoals == false && employeeId && year &&  !formValues[0]?.main_goal_id && (
                  <Button
                    variant="success"
                    type="submit"
                    style={{ marginLeft: "30px" }}
                    disabled={remainingWeightage < 0}
                    onClick={handleSubmit} // Disable if total weightage exceeds 100
                  >
                    Submit Goal
                  </Button>
                )}
              </div>
            </Form>
          </Container>
        </div>
      </div>

      {acknowledgmentPopup.showAcknowledgmentPopup ? (
        <AcknowledgmentPopup
          popupVariant={acknowledgmentPopup.popupVariant}
          popupMessage={acknowledgmentPopup.popupMessage}
          popupIcon={acknowledgmentPopup.popupIcon}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CreateGoal;
