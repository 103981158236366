/* eslint-disable no-unused-expressions */

import { Table } from "antd";
import { AddHoliday } from "../../../components/modelpopup/AddHoliday";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import Breadcrumbs from "../../../components/Breadcrumbs";
import config from "../../../config";
import { formatDateInTimezone } from "../../../utils/formatDateInTimezone";
import { companyID } from './../../../auth';
import * as XLSX from 'xlsx';
import UploadHoliday from "../../../components/modelpopup/UploadHoliday";
import { getDayOfWeek } from "../../../utils/getDayOfWeek";

const Holidays = () => {
  const [users, setUsers] = useState([]);
  const [configurationHolidays, setConfigurationHolidays] = useState([]);
  const [show, setShow] = useState(false);
  const [holidayListDownloaded, setHolidayListDownloaded] = useState(false);

  useEffect(() => {
    const company_id = companyID();
    const date = new Date();
    const currentYear = date.getFullYear();
    axios
      .get(`${config.API_BASE_URL}holidays/${company_id}/${currentYear}`)
      .then((res) => setUsers(res.data))
      .catch((err) => console.log(err));
  }, [show]);

  const userElements = users.map((user, index) => ({
    key: index,
    id: index + 1,
    Title: user.occasion,
    HolidayDate: formatDateInTimezone(user.holiday_date),
    Day: user.day,
  }));

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Title",
      dataIndex: "Title",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.Title.length - b.Title.length,
    },
    {
      title: "Holiday Date",
      dataIndex: "HolidayDate",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.HolidayDate.length - b.HolidayDate.length,
    },
    {
      title: "Day",
      dataIndex: "HolidayDate",
      render: (text) => <span>{getDayOfWeek(text)}</span>,
      sorter: (a, b) => a.Day.length - b.Day.length,
    },
    // {
    //   title: "Action",
    //   render: () => (
    //     <div className="dropdown dropdown-action ">
    //       <Link
    //         to="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="material-icons">more_vert</i>
    //       </Link>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit_holiday"
    //         >
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </Link>
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete"
    //         >
    //           <i className="fa-regular fa-trash-can m-r-5" /> Delete
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    //   sorter: true,
    // },
  ];

  const location = useLocation();

  const fetchConfigurationHolidays = () => {
    axios.get(`${config.API_BASE_URL}configuration-holidays`)
      .then((res) => {
        const holidays = res?.data?.data.map((holiday) => ({ Occasion: holiday.occasion, HolidayDates: '', Year: '' })
        )
        setConfigurationHolidays(holidays);
      })
  }

  const handleDownload = async() => {
    fetchConfigurationHolidays()
    const worksheetData = configurationHolidays.map((holiday) => ({
      Occasion: holiday.Occasion,
      HolidayDates: holiday.HolidayDates,
      Year: holiday.Year,
    }));

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Holidays');

    XLSX.writeFile(workbook, 'Holidays.xlsx');
    setHolidayListDownloaded(true)
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <div
        className={
          location.pathname.includes("leave-dashboard") ? "" : "page-wrapper"
        }
      >
        <div
          className={
            location.pathname.includes("leave-dashboard")
              ? ""
              : "content container-fluid"
          }
        >
          {!location.pathname.includes("leave-dashboard") && (
            <Breadcrumbs
              maintitle="Holidays"
              title="Dashboard"
              subtitle="Holidays"
              name="Add Holidays"
              openModal={handleShow}
              page='holidays'
              action='create'
            />
          )}
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={userElements?.length > 0 ? userElements : []}
                  className="table-striped"
                  rowKey={(record) => record.id}
                  scroll={{ x: true }}
                  tableLayout="auto"
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <AddHoliday />
      {show && <UploadHoliday show={show} setShow={setShow} handleDownload={handleDownload} holidayListDownloaded={holidayListDownloaded} setHolidayListDownloaded={setHolidayListDownloaded} />}
      <DeleteModal Name="Delete Holiday" />
    </>
  );
};

export default Holidays;
