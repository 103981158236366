import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Empty, Pagination } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import SearchBox from "../../../components/SearchBox";
import config from "../../../config";
import User from "../../../assets/img/icons/user3.png";
import EmployeeAssetsDetail from "./EmployeeAssetsDetail";
import axios from "axios";
import { companyID } from "../../../auth";
import AssignAssetModel from "../../../components/AssignAssetModel";
import AddAssetModel from "../../../components/AddAssetModel";
import AssetDetails from "./AssetDetails";

const AssetAssignment = () => {

  const [showAssetDetail, setShowAssetDetail] = useState(false);
  const [assetDetail, setAssetDetail] = useState([]);
  const [selectedEmployeeDetail, setSelectedEmployeeDetail] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showCompanyAssets, setShowCompanyAssets] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(5)
  const detail = {
    selectedDate: null,
    selectedAssetType: '',
    serialNumber: '',
    modelNumber: '',
    manufacturer: '',
    warranty: ''
  }
  const [assetDetails, setAssetDetails] = useState(detail)
  const [assetModel, setAssetModel] = useState({
    title:'Add',
    buttonText:'Add'
  })

  const startIndex = (currentPage - 1) * paginationValue;
  const endIndex = startIndex + paginationValue;
  const selectedDetails = assetDetail?.slice(startIndex, endIndex);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPaginationValue(pageSize);
  };

  //   const user = localStorage.getItem("user");
  //   let department_id;
  //     if(user){
  //       const companyDetails = JSON.parse(user);
  //       department_id = companyDetails.department_id
  //     }
  const company_id = companyID()

  const fetchEmployeesAssets = () => {
    axios.get(`${config.API_BASE_URL}employee-assets/${company_id}`)
      .then((res) => {
        const seen = new Set();
        const assetsData = res.data.data.filter(employee => seen.has(employee.employee_id) ? false : seen.add(employee.employee_id));
        console.log('employee-assets : ', res.data.data)
        setEmployeeDetails(res.data.data);
        setAssetDetail(assetsData);
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        //loader
      })
  }

  useEffect(() => {
    if (company_id) {
      fetchEmployeesAssets();
    }
  }, [isModalOpen])

  const getNameOfEmployee = () => {
    if (selectedEmployeeDetail.length > 0) {
      const middle_name = selectedEmployeeDetail[0].middle_name ? selectedEmployeeDetail[0].middle_name + ' ' : ''
      return selectedEmployeeDetail[0].first_name + ' ' + middle_name + selectedEmployeeDetail[0].last_name
    }
    return 'Employee'
  }

  function createEmployeeMap(employees) {
    const employeeMap = new Map();
    employees.forEach(employee => {
      if (!employeeMap.has(employee.employee_id)) {
        employeeMap.set(employee.employee_id, []);
      }
      employeeMap.get(employee.employee_id).push(employee);
    });
    return employeeMap;
  }

  function getEmployeeDataById(employeeMap, employee_id) {
    return employeeMap.get(employee_id) || [];
  }

  const filterSelectedEmployeeDetail = (selectedEmployee, employeeId) => {

    const employeeMap = createEmployeeMap(selectedEmployee);
    const employeeData = getEmployeeDataById(employeeMap, employeeId);

    return employeeData;
  }

  useEffect(() => {
    getNameOfEmployee();
  }, [selectedEmployeeDetail])

  const openModal = () => {
    setIsModalOpen(true);
  };

  const openAddAssetModal = () => {
    setIsAddModalOpen(true);
  };

  useEffect(() => {
    if (isModalOpen || isAddModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, [isModalOpen, isAddModalOpen]);

  const fetchSelectedEmployeeData = (selectedEmployee) => {
    const employeeData = filterSelectedEmployeeDetail(employeeDetails, selectedEmployee)
    setSelectedEmployeeDetail(employeeData);
  }

  useEffect(() => {
    if (selectedEmployee) {
      fetchSelectedEmployeeData(selectedEmployee);
    }
  }, [isModalOpen, employeeDetails])

  const openEmployeeDetail= (e, detail) =>
    {
      e.preventDefault();
      setShowAssetDetail(true);
      fetchSelectedEmployeeData(detail.employee_id);
      setSelectedEmployee(detail.employee_id);
    }

  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={showCompanyAssets ? 'Company Assets' : showAssetDetail ? `${getNameOfEmployee()} Assets Details` : "Employees Assets"}
            title={showAssetDetail || showCompanyAssets ? 'Employees Assets' : "Dashboard"}
            subtitle={showCompanyAssets ? 'Company Assets' : showAssetDetail ? getNameOfEmployee() : "Employees Assets"}
            titleLink={showAssetDetail || showCompanyAssets ? 'asset-assignment' : null}
            name={showCompanyAssets ? "Add asset" : "Assign asset"}
            openModal={showCompanyAssets ? openAddAssetModal : openModal}
            page='asset-assignment'
            action='create'
          />
          {!showAssetDetail && !showCompanyAssets ? <div className="d-flex justify-content-between">
            <div className="flex-grow-1">
            <SearchBox setPaginationValue={setPaginationValue} />
            </div>
            <div className="d-flex justify-content-end">
              {/* <button className="btn btn-link" onClick={()=>setIsAddModalOpen(true)}>Add Asset</button> */}
              <button className="btn btn-link" onClick={() => setShowCompanyAssets(true)}>Company Assets</button>
            </div>
          </div>
            : <></>}
          {showCompanyAssets ? <AssetDetails setShowCompanyAssets={setShowCompanyAssets} setIsAddModalOpen={setIsAddModalOpen} setAssetDetails={setAssetDetails} setAssetModel={setAssetModel} isAddModalOpen={isAddModalOpen}/> : !showAssetDetail ? <><div className="row">
            {assetDetail.length <= 0 ? <Empty description="No Assets details available." /> :
              selectedDetails.map((detail) => (
                <div
                  className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                  key={detail.id}
                >
                  <div className="profile-widget">
                    <div className="profile-img">
                      <Link to='#' className="avatar" onClick={(e) => openEmployeeDetail(e, detail)}>
                        <img src={detail.photo ? `${config.IMAGE_URL}${detail.photo}` : User} alt="" />
                      </Link>
                    </div>
                    <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                      <Link to="#" onClick={(e) => openEmployeeDetail(e, detail)}>{detail?.first_name} {detail?.middle_name} {detail?.last_name}</Link>
                    </h4>
                    <div className="small text-muted">{detail?.designation_name ? detail?.designation_name : ''}</div>
                  </div>
                </div>
              ))}
          </div>
            <div className="d-flex justify-content-end mb-4">
              <Pagination
                current={currentPage}
                pageSize={paginationValue}
                total={assetDetail.length}
                onChange={handlePageChange}
              />
            </div> </> : <EmployeeAssetsDetail selectedEmployeeDetail={selectedEmployeeDetail} setShowAssetDetail={setShowAssetDetail} />}
        </div>
      </div>
      <AssignAssetModel setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
      <AddAssetModel assetModel={assetModel} setAssetModel={setAssetModel} setIsAddModalOpen={setIsAddModalOpen} isAddModalOpen={isAddModalOpen} assetDetails={assetDetails} setAssetDetails={setAssetDetails} detail={detail} />
      {(isModalOpen || isAddModalOpen) && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default AssetAssignment;
