 export function convertMillisecondsDynamic(ms) {
  const millisecondsInMinute = 60 * 1000;
  const millisecondsInHour = 60 * millisecondsInMinute;
  const millisecondsInDay = 24 * millisecondsInHour;
  const millisecondsInYear = millisecondsInDay * 365.25; // Including leap years
  const millisecondsInMonth = millisecondsInYear / 12;

  const years = Math.floor(ms / millisecondsInYear);
  ms %= millisecondsInYear;

  const months = Math.floor(ms / millisecondsInMonth);
  ms %= millisecondsInMonth;

  const days = Math.floor(ms / millisecondsInDay);
  ms %= millisecondsInDay;

  const hours = Math.floor(ms / millisecondsInHour);
  ms %= millisecondsInHour;

  const minutes = Math.floor(ms / millisecondsInMinute);

  if (years > 0 && months > 0 && days > 0) {
    return `${years} year's, ${months} month's, ${days} day's, ${hours} hour's, and ${minutes} minute's`;
  } else if (years > 0 && months > 0) {
    return `${years} year's, ${months} month's`;
  } else if (years > 0) {
    return `${years} year's`;
  } else if (months > 0) {
    return `${months} month's`;
  } else if (days > 0) {
    return `${days} day's`;
  } else if (hours > 0) {
    return `${hours} hour's`;
  } else {
    return `${minutes} minute's`;
  }
}

