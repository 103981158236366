/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Table } from "antd";
import SearchBox from "../../../../components/SearchBox";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import GoalTrackingModal from "../../../../components/modelpopup/GoalTrackingModal";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";
import ProtectedButton from "../../../../Routes/Approuter/ProtectedButton";
import axios from "axios";
import config from "../../../../config";
import { formatDateInTimezone, getCurrentDate } from "./../../../../utils/formatDateInTimezone";
import DatePicker from "react-datepicker";
import "../../../../assets/css/goalTracking.css";
import { Button } from "react-bootstrap";
import { companyID } from "../../../../auth";
import { convertMillisecondsDynamic } from './../../../../utils/convertmilisecondinDyamic';
import GoalActivationModal from "../../../../components/modelpopup/GoalActivationModal";

const GoalSetting = () => {
  const [paginationValue, setPaginationValue] = useState(5);
  const date = new Date();
  const current_year = date.getFullYear();

  const [selectedYear, setSelectedYear] = useState(current_year);
  const [show , setShow] = useState(false);
  const [handleData , setHandleData]  = useState({});
  const [activationState , setActivationState] = useState(null);
  const emp_id = localStorage.getItem('employeeID');



  const activationHandler = (record , state) =>{
    setShow(true);
    setHandleData(record);
    setActivationState(state);
  }

  const handleClose=()=>{
    setShow(false);
    setHandleData({});
    setActivationState(null);
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      // sorter: (a, b) => a.main_goal.length - b.main_goal.length,
    },
    {
      title: "Time ",
      dataIndex: "time",
      // sorter: (a, b) => a.weightage.length - b.weightage.length,
      render: (text) => <div className="">{convertMillisecondsDynamic(text)}</div>,
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      // sorter: (a, b) => a.start_date.length - b.start_date.length,
      render: (text, record) => <>{record.deadline ? formatDateInTimezone(record?.deadline) : '-'}</>,
    },
    {
      title: "Status",
      dataIndex: "active",
      // sorter: (a, b) => a.end_date.length - b.end_date.length,
      render: (text, record) => <>
      {
        text==0 ?<span className="bg-danger badge">InActive</span> :  <span className="bg-success badge">Active</span>
      }
      </>,
    },

    {
        title: "Action",
        dataIndex: "id",
        render: (text, record) => <>
          {record.active == 1 ? <i className="fa fa-toggle-on text-success" onClick={()=>activationHandler(record , false)} ></i> :
           <i class="fa fa-toggle-off text-muted" onClick={()=>activationHandler(record , true)}></i> }
        </>,
      },
  ];


 const [goalConfigurationData , setGoalConfihurationData] = useState(true);

  const fetchConfiguration = async () => {
    const company_id = companyID();
    const response = await axios.get(
      `${config.API_BASE_URL}performance/goal-config?company_id=${company_id}`
    );
    if (response?.data?.result?.length > 0) {
        setGoalConfihurationData(response?.data?.result);
    }
  };

  useEffect(() => {
    fetchConfiguration();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={`Goals Setting`}
            title="Dashboard"
            subtitle="Goal Setting"
            // modal="#add_goal"
            // name="Add New"
          />
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <div className="d-flex justify-content-between align-item-center">
                  <div className=" flex-grow-1">
                    <SearchBox setPaginationValue={setPaginationValue} />
                  </div>
                  <div className="custom-datepicker-wrapper flex-end flex-grow-1">
                    <DatePicker
                      selected={new Date(selectedYear, 0, 1)}
                      onChange={(date) => {
                        const year = date.getFullYear();
                        setSelectedYear(year);
                      }}
                      dateFormat="yyyy"
                      showYearPicker
                      showMonthDropdown={false}
                      showDayDropdown={false}
                      className="form-control custome_date_change"
                      minDate={new Date(2020, 0, 1)}
                      maxDate={new Date(current_year, 11, 31)}
                      id="year-picker"
                    />
                   
                  </div>
                </div> */}
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={goalConfigurationData.length > 0 && goalConfigurationData}
                  pagination={{ pageSize: paginationValue }}
                  // rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <GoalTrackingModal />
      <GoalActivationModal  fetchConfiguration={fetchConfiguration} handleClose={handleClose} activationState={activationState} handleData={handleData} show={show} goalConfigurationData={goalConfigurationData}/>
    </>
  );
};

export default GoalSetting;
