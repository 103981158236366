import { format } from 'date-fns';

export const getDayOfWeek = (dateString) => {
    try {
      const date = new Date(dateString);
      return format(date, 'EEEE'); 
    } catch (error) {
      console.error('Invalid date format:', error);
      return null;
    }
  };