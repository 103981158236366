import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation, useParams, Outlet } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useDispatch } from "react-redux";
import { login } from "../../../user";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../config";
import login_icon from "../../../assets/img/login_icon.png";
import createCustomSchema from "../../../fieldValidator/fieldValidator";
import { isValidString } from "../../../utils/validateDateFields";
import { capitalizeWords } from "../../../utils/basicFunctions";
import IconsOfFunctionalities from './../Employees/IconsOfFunctionalities';
import Header from "../../layout/Header";
import { Sidebar } from "react-feather";
import OffCanvas from "../../../components/OffCanvas";
import FindCompanyModal from "../../../components/modelpopup/FindCompanyModal";
import CompanyCoordinatesError from "./CompanyCoordinatesError";
import Loader from "../../../components/Loader";
import { onLoginSuccess } from "./TrackingHandler";
import { fetchCompanySubscription, checkSubscriptionExpiry } from '../../../services/SubscriptionService'

const Login = ({ setToken }) => {
  const company_id = localStorage.getItem('company_id');
  const [validCompanyDetails, setValidCompanyDetails] = useState([]);
  const [companyUrl, setCompanyUrl] = useState(false);
  const { company_name } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [emailError, setEmailError] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyColor, setCompanyColor] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [handleCompany, setHandleComapany] = useState("");
  const [emailCount, setEmailCount] = useState(0);
  const [emailCountError, setEmailCountError] = useState(false);
  const [isInValidPassword, setIsInValidPassword] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [role, setRole] = useState(null);
  const [locationAllowed, setLocationAllowed] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [locationErrorMessage, setLocationErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [companyId, setCompanyId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workLocation, setWorkLocation] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [error, setError] = useState({
    subscriptionExpired: null,
    responseError: null
  });

  const getSubscriptionStatus = async (companyId) => {
    try {
      const subscription = await fetchCompanySubscription(companyId);
      const expired = checkSubscriptionExpiry(subscription);
      setIsExpired(expired);
      localStorage.setItem("isSubscriptionExpired", JSON.stringify(expired));
      if (expired) {
        setError(prev => ({...prev, subscriptionExpired:'Please reach out to your administrator for assistance.'}));
      }
      return expired
    } catch (err) {
      setError(prev => ({...prev, responseError:'Failed to fetch subscription status.'}));
      console.error(err);
    }
  };

  const handleCompanyCoordinatesErrorModal = () => {
    setShowModal(false);
  };

  const isAuthenticated = !!localStorage.getItem("token");

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }
    if (company_name && isValidString(company_name)) {
      axios.get(`${config.API_BASE_URL}auth/login/${company_name}`)
        .then((res) => {
          if (res?.data?.success == true) {
            setValidCompanyDetails(res?.data?.result);
          }
        })
        .catch(err => console.log(err))
    }
    else {
      console.log("not valid company")
    }
  }, [companyUrl, company_name])


  const validationSchema = Yup.object().shape({
    email: createCustomSchema("email", {
      message: "Enter Your  Email!",
    }),
  });
  const details = localStorage.getItem("loginDetails");
  const loginData = JSON.parse(details);
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });


  const [authEmailRouteError, setAuthEmailRouteError] = useState("");
  const [findCompanyName, setFindCompanyName] = useState("");
  const [doneNavigation, setDoneNavigation] = useState(false);

  const validateEmail = async (email) => {
    setLoading(true);
    setAuthEmailRouteError("");
    try {
      const trimmedEmail = email.trim();
      const response = await axios.post(
        `${config.API_BASE_URL}auth/validate-email`,
        { email: trimmedEmail }
      );
      const responseCompanyName = response?.data?.company?.trim()?.replace(/\s+/g, "-").toLowerCase();
      const roleId = response?.data?.role_id;
      setRole(roleId);
      setCompanyId(response?.data?.company_id);
      setWorkLocation(response?.data?.work_location);
      if ((responseCompanyName !== company_name) && roleId !== 1) {
        setFindCompanyName(responseCompanyName);
        let message
        if (company_name) {
          message = `*Your Account is not Exists in ${capitalizeWords(company_name)}. Please Login with correct URL or connect with your company administration! `
        } else {
          message = 'Please Login with correct URL or connect with your company administration!'
        }

        setAuthEmailRouteError(message);
        setStep(1);
        navigate(window.location.pathname, { replace: true })
        // navigate(`../${responseCompanyName}/`)
        setCompanyUrl(true);
        setLoading(false);
        return;
      }
      setLoading(false);
      return response?.data;
    } catch (error) {
      setLoading(false);
      return false;
    }
  };

  const handleEmailSubmit = async (data) => {
    setLoading(true);
    setValue("email", data.email);
    if (!data?.email) {
      setEmailError(true);
      setLoading(false);
      return;
    }
    const emailIsValid = await validateEmail(data.email);
    localStorage.setItem("invalidUser", data?.email);
    localStorage.setItem("work_location", emailIsValid?.work_location);
    let inLocation;
    if (emailIsValid?.work_location == 1) {
      inLocation = await getEmployeeCurrentPosition(emailIsValid?.company_id);
    }
    if (inLocation || emailIsValid?.work_location != 1) {
      setLoading(false);
      if (emailIsValid?.exists) {
        setLoading(false);
        if (emailIsValid?.match === false) {
          const company_url = emailIsValid?.company?.trim()?.replace(/\s+/g, "-").toLowerCase()
          localStorage.setItem("company_url", company_url);
          const company = extractCompanyName(data?.email);
          setCompanyName(company);
          getSubscriptionStatus(emailIsValid?.company_id )
          .then(isExpired => {
            if (!isExpired || emailIsValid?.role_id == 1) {
              setStep(2);
              // setEmailError(false);
              setEmailCountError(false);
              setError({
                subscriptionExpired: null,
                responseError: null
              });
              }
          })
          .catch(error => {
            console.error('Error checking subscription status:', error);
          });
        } else {
          navigate("/forgot-password", {
            state: { companyLogo: `${config.IMAGE_URL}${validCompanyDetails[0]?.company_logo}`, companyThemeColor: validCompanyDetails[0]?.colour, companyName: validCompanyDetails[0]?.company_name }
        })}
      } else {
        setLoading(false);
        if (emailCount > 2) {
          setEmailCountError(true);
          setEmailError(false);
        } else {
          setEmailError(true);
          setEmailCount((prev) => prev + 1);
          setEmailCountError(false);
        }
      }
    } else {
      setLoading(false);
    }

  };


  const extractCompanyName = (email) => {
    const domain = email.split("@")[1];
    const company = domain.split(".")[0];
    return company;
  };

  const handleLoginSubmit = async (data) => {
    setLoading(true);
    if (!data?.password) {
      setPasswordError("* Please enter password!");
      setIsInValidPassword(false);
      setLoading(false);
      return;
    }
    setPasswordError("");
    let inLocation;
    if (workLocation == 1) {
      inLocation = await getEmployeeCurrentPosition(companyId);
    }
    if (inLocation || workLocation != 1) {
      setLoading(false);
      try {
        const response = await axios.post(
          `${config.API_BASE_URL}auth/login`,
          data
        );
        if (response.status === 200) {
          const { token, user } = response.data;
          const responseCompanyName = user?.company?.name?.trim()?.replace(/\s+/g, "-").toLowerCase();
          if (company_name === responseCompanyName || role === 1) {
            const { exp } = JSON.parse(atob(token.split(".")[1]));
            const expirationTime = exp * 1000;
            localStorage.setItem("token_expiration", expirationTime.toString());
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("colorschema", user.company.colour);
            localStorage.setItem("companyColor", user.company.colour);
            localStorage.setItem("companyName", user.company.name);
            localStorage.setItem("employee_role", user?.employee_role);

            setCompanyColor(user?.company?.colour);
            dispatch(login(user));
            setIsNavigate(true);
            setToken(token);
            onLoginSuccess(user?.id, user?.work_start_time, user?.work_end_time);
            // toast.success("Login successful!");
            // navigate(window.location.pathname, { replace: true })
            navigate(`../${responseCompanyName}/`);
          } else {

          }

        } else {
          setEmailError(true);
          setIsInValidPassword(true);
        }
      } catch (error) {
        console.log("err", error);
        setEmailError(true);
        setIsInValidPassword(true);
      }
    } else {
      setLoading(false);
    }
  };


  useEffect(() => {
    setValue("email", localStorage.getItem("invalidUser"));
    setValue("password", localStorage.getItem("password"));
  }, []);

  const [eye, seteye] = useState(true);

  const onEyeClick = () => {
    seteye(!eye);
  };

  useEffect(() => {
    const company_url = localStorage.getItem('company_url')
    if (isAuthenticated) {
      // navigate(`../${company_name}/`);
      navigate(window.location.pathname, { replace: true });
      return;
    }
    if (company_url) {
      navigate(`../${company_url}`)
      return
    }
  }, [isAuthenticated, navigate, company_name]);

  useEffect(() => {
    setAuthEmailRouteError("");
    setEmailError(false);
  }, [doneNavigation])

  const getEmployeeCurrentPosition = async (company_id) => {
    setLoading(true);
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        setLoading(false);
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ latitude, longitude });
            const locationAllowed = await checkEmployeeLocation(latitude, longitude, company_id);
            // const locationAllowed = await checkEmployeeLocation('26.85338427114405', '81.0168830277534', company_id);
            resolve(locationAllowed);  // Return true or false based on checkEmployeeLocation
          },
          (error) => {
            setShowModal(true)
            setLocationAllowed(true);
            setLocationErrorMessage('Failed to retrieve location. Turn on location and give access to the app.');
            console.log('Location error:', error);
            setIsLoading(false);
            resolve(false);  // Return false on error
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,  // Max time to wait for location
            maximumAge: 0    // Don't use a cached location
          }
        );
      } else {
        setLoading(false);
        setLocationErrorMessage('Geolocation is not supported by your browser.');
        setIsLoading(false);
        resolve(false);  // Return false if geolocation is not supported
      }
    });
  };

  const checkEmployeeLocation = async (latitude, longitude, company_id) => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}auth/company_coordinates`, { latitude, longitude, company_id });
      if (response.data.allowed) {
        setShowModal(false);
        setLocationAllowed(false);
        return true;  // Return true if location is allowed
      } else {
        setShowModal(true);
        setLocationAllowed(true);
        setLocationErrorMessage('You are not within the allowed location. Try to locate near company radius.');
        return false;  // Return false if location is not allowed
      }
    } catch (error) {
      setLocationErrorMessage('Error checking location.');
      setIsLoading(false);
      return false;  // Return false on error
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (company_id) {
      getEmployeeCurrentPosition(company_id);
    }
  }, []);


  // if (isLoading&&companyId) {
  //   return <div>Loading...</div>;
  // }

  // if (locationAllowed) {
  //   return <div>{locationErrorMessage || "You're not within the allowed location."}</div>;
  // }

  if (isAuthenticated) {
    const companyName = localStorage.getItem("companyName");
    navigate(`../${companyName.trim()?.replace(/\s+/g, "-").toLowerCase()}`);
    return;
  }

  return (
    <>
      <div>
        <ToastContainer />
        <div className="account-page" style={{ backgroundColor: validCompanyDetails[0]?.colour, overflow:'auto' }}>
          <div className="main-wrapper">
            <div className="account-content">
              {/*<Link to="/job-list" className="btn btn-primary apply-btn">
              Apply Job
            </Link> */}
              <div className="container" >
                <div className="account-logo" >
                  <Link to="#" >
                    <img src={validCompanyDetails[0]?.company_logo ? `${config.IMAGE_URL}${validCompanyDetails[0]?.company_logo}` : login_icon} alt={validCompanyDetails[0]?.company_name || 'app logo'} style={{ borderRadius: "50%", zIndex: "100", backgroundColor: "white", width: "150px", height: "150px", padding: '2px' }} />
                  </Link>
                </div>
                <div className="account-box">
                  <div className="account-wrapper">
                    <h3 className="account-title">Login</h3>
                    <p className="account-subtitle">
                      Access to {validCompanyDetails[0]?.company_name ? capitalizeWords(validCompanyDetails[0]?.company_name) : 'your'} dashboard
                    </p>
                    <div>
                      {step === 1 ? (
                        <form onSubmit={handleSubmit(handleEmailSubmit)}>
                          <div className="input-block mb-4">
                            <label className="col-form-label">
                              Email Address
                            </label>
                            <Controller
                              name="email"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${emailError ? "error-input" : ""
                                    }`}
                                  type="text"
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                  autoFocus={true}
                                />
                              )}
                            />
                            <span className="text-danger">
                              {errors.email?.message ? '* ' : ''}
                              {(authEmailRouteError && (authEmailRouteError)) || errors.email?.message ||
                                (emailError && "*Please Enter Valid Email!") ||
                                (emailCountError &&
                                  "*Please Contact Your Organisation !")}
                            </span>
                            {error?.subscriptionExpired ? <span className="text-danger">* 
                              {error?.subscriptionExpired}
                            </span> : <></>}
                          </div>
                          <div className="input-block text-center">
                            <button
                              className="btn btn-primary account-btn"
                              type="submit"
                            >
                              Validate email
                            </button>
                          </div>
                          {authEmailRouteError &&
                            (<>
                              Help - {" "}
                              <Link to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#find_company"
                                style={{ color: "#201a7a", fontSize: "14px" }}>Find Your Company URL</Link>
                            </>)}
                        </form>
                      ) : (
                        <form onSubmit={handleSubmit(handleLoginSubmit)}>
                          <div className="input-block mb-4">
                            <div className="row">
                              <div className="col">
                                <label className="col-form-label">Password</label>
                              </div>
                              <div className="col-auto">
                                <Link
                                  className="text-muted"
                                  to="/forgot-password"
                                  state={{ companyLogo: `${config.IMAGE_URL}${validCompanyDetails[0]?.company_logo}`, companyThemeColor: validCompanyDetails[0]?.colour, companyName: validCompanyDetails[0]?.company_name }}
                                >
                                  Forgot password?
                                </Link>
                              </div>
                            </div>
                            <div style={{ position: "relative" }}>
                              <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className={`form-control ${errors?.password ? "error-input" : ""
                                      }`}
                                    type={eye ? "password" : "text"}
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoFocus={true}
                                  />
                                )}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  right: "5%",
                                  top: "30%",
                                }}
                                onClick={onEyeClick}
                                className={`fa-solid ${eye ? "fa-eye-slash" : "fa-eye"
                                  }`}
                              />
                            </div>

                            <span className="text-danger">
                              {errors.password?.message || passwordError}
                            </span>
                            {isInValidPassword && (
                              <span className="text-danger">
                                * Please enter correct password.
                              </span>
                            )}
                          </div>
                          <div className="input-block text-center">
                            <button
                              className="btn btn-primary account-btn"
                              type="submit"
                            >
                              Login
                            </button>
                          </div>
                        </form>
                      )}


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FindCompanyModal findCompanyName={findCompanyName} setDoneNavigation={setDoneNavigation} />
      <CompanyCoordinatesError
        errorMessage={locationErrorMessage}
        show={showModal}
        handleClose={handleCompanyCoordinatesErrorModal}
      />
      <Loader show={loading} loadingMessage="" />
    </>
  );
};

export default Login;
