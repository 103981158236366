import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import SearchBox from "../../../components/SearchBox";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import config from "../../../config";
import { getPropertyByKey } from "../../../utils/getPropertyByKey";
import { companyID } from "../../../auth";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AcknowledgePolicyModal from "../HR/Policies/AcknowledgePolicyModal";
import Loader from "../../../components/Loader";
import AcknowledgmentPopup from "../../../utils/AcknowledgmentPopup";

const AssignedAsset = () => {

    const [assetStatus, setAssetStatus] = useState([]);
    const [assetType, setAssetType] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [employeeAsset, setEmployeeAsset] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [viewPdfFile, setViewPdfFile] = useState(null);
    const [selectedAssetId, setSelectedAssetId] = useState(null);
    const [loading, setLoading] = useState({
        show: false,
        message: ''
    });
    const [acknowledgmentPopup, setAcknowledgmentPopup] = useState({
        showAcknowledgmentPopup: false,
        popupVariant: '',
        popupMessage: '',
        popupIcon: ''
    })


    const handleModalClose = () => {
        setShowModal(false);
        setViewPdfFile(null);
    }

    const hideAcknowledgmentPopup = () => {
        const resetAcknowledgmentPopupData = () => {
            setAcknowledgmentPopup({
                showAcknowledgmentPopup: false,
                popupVariant: '',
                popupMessage: '',
                popupIcon: ''
            })
        }
        setTimeout(() => resetAcknowledgmentPopupData()
            , 2000);
    };

    const handleModalSubmit = (data) => {
        if (selectedAssetId) {
            setLoading({ message: 'Acknowledging Policy...', show: true })
            axios.put(`${config.API_BASE_URL}employee-assets/${selectedAssetId}`, data)
                .then((res) => {
                    console.log(res)
                    setAcknowledgmentPopup({
                        showAcknowledgmentPopup: true,
                        popupVariant: 'success',
                        popupMessage: 'Successfully Acknowledged Policy !',
                        popupIcon: 'fa-regular fa-circle-check'
                    });
                })
                .catch((err) => {
                    console.log(err)
                    setAcknowledgmentPopup({
                        showAcknowledgmentPopup: true,
                        popupVariant: 'danger',
                        popupMessage: 'Something went wrong, Try again !',
                        popupIcon: 'fa-regular fa-circle-xmark'
                    })
                })
                .finally(() => {
                    handleModalClose();
                    setLoading({
                        show: false,
                        message: ''
                    })
                    hideAcknowledgmentPopup()
                })
        }

    };

    const employee_id = localStorage.getItem("employeeID");
    const company_id = companyID()

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const fetchEmployeesAssets = () => {
        axios.get(`${config.API_BASE_URL}employee-assets/${company_id}?employee_id=${employee_id}`)
            .then((res) => {
                setEmployeeAsset(res.data.data);
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                //loader
            })
    }

    useEffect(() => {
        if (company_id && employee_id) {
            fetchEmployeesAssets();
        }
    }, [showModal])

    useEffect(() => {
        fetchAssetStatus();
        fetchAssetType();
    }, [])

    const fetchAssetStatus = () => {
        axios.get(`${config.API_BASE_URL}assets-status`)
            .then((res) => {
                setAssetStatus(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const fetchAssetType = () => {
        axios.get(`${config.API_BASE_URL}asset-types`)
            .then((res) => {
                setAssetType(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const downloadFile = async (file) => {

        let policy;

        if (file) {
            axios.get(`${config.API_BASE_URL}hr/policy-data-update/${file}`)
                .then(async (res) => {
                    policy = res.data.result
                    try {
                        setViewPdfFile(`${config.IMAGE_URL}${policy[0].policy_file}`);
                    } catch (error) {
                        console.error('Error downloading file:', error);
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const columns = [
        {
            title: "Asset",
            dataIndex: "name",
            key: 'name',
            render: (text) => text
        },
        {
            title: "Serial number",
            dataIndex: "serial_number",
            key: 'serial_number',
            render: (text) => text
        },
        {
            title: "Manufacturer",
            dataIndex: "manufacturer",
            key: "manufacturer",
            render: (text) => text
        },
        {
            title: "Model",
            dataIndex: "model",
            key: "model",
            render: (text) => text
        },
        {
            title: "Assigned_date",
            dataIndex: "assigned_date",
            key: "assigned_date",
            render: (text) => {
                if (text) {
                    return text.split('T')[0]
                } else {
                    return '-'
                }
            }
        },
        {
            title: "Policy acknowledged",
            dataIndex: "policy_acknowledged",
            key: "policy_acknowledged",
            render: (text) => text == 1 ? "Yes" : "No"
        },
        {
            title: "Employee received",
            dataIndex: "employee_received",
            key: "employee_received",
            render: (text) => text == 1 ? "Yes" : "No"
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text) => getPropertyByKey(assetStatus, 'id', text, 'name')
        },
        {
            title: "Action",
            render: (text, record) => (
                <div>
                    <div className="d-flex gap-2 mt-2">
                        <Link
                            style={{ cursor: `${record.policy_acknowledged == 0 ? 'pointer' : 'not-allowed'}` }}
                            onClick={(e) => {
                                e.preventDefault();
                                if (record.policy_acknowledged == 0) {
                                    setShowModal(true);
                                    downloadFile(record.policy);
                                    setSelectedAssetId(record.id);
                                }
                            }}
                        >
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                                        Acknowledge Policy
                                    </Tooltip>
                                }
                            >
                                <i className={`fa fa-file-contract m-r-5 ${record.policy_acknowledged == 0 ? 'text-success' : 'text-muted'}`} />
                            </OverlayTrigger>
                        </Link>
                    </div>
                </div>
            ),
        },
    ];
    return (
        <div>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <Breadcrumbs
                        maintitle="Employees Assets"
                        title="Dashboard"
                        subtitle="Employees Assets"
                    />
                    <SearchBox setPaginationValue={setPageSize} />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <Table
                                    className="table-striped"
                                    columns={columns}
                                    dataSource={employeeAsset}
                                    rowKey={(record) => record.id}
                                    loading={loading.show}
                                    pagination={{
                                        current: currentPage,
                                        pageSize: pageSize,
                                        total: employeeAsset.length,
                                        showSizeChanger: true,
                                        onChange: handlePageChange,
                                        pageSizeOptions: ['5', '10', '15']
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {viewPdfFile && <AcknowledgePolicyModal
                        show={showModal}
                        onClose={handleModalClose}
                        onSubmit={handleModalSubmit}
                        pdfUrl={viewPdfFile}
                    />}
                    {loading.show && <Loader show={loading.show} loadingMessage={loading.message} />}
                    {acknowledgmentPopup.showAcknowledgmentPopup ? <AcknowledgmentPopup
                        popupVariant={acknowledgmentPopup.popupVariant}
                        popupMessage={acknowledgmentPopup.popupMessage}
                        popupIcon={acknowledgmentPopup.popupIcon} /> : <></>}
                </div>
            </div>
        </div>
    );
};

export default AssignedAsset;
