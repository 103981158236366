import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import config from "../config";
import Select from "react-select";
import { companyID } from "../auth";
import Loader from "./Loader";
import AcknowledgmentPopup from "../utils/AcknowledgmentPopup";
import { format } from "date-fns";

const AddAssetModel = ({ isAddModalOpen, setIsAddModalOpen, assetDetails, setAssetDetails, detail, assetModel, setAssetModel }) => {

    const [assetTypes, setAssetTypes] = useState([]);
    const [newAssetType, setNewAssetType] = useState("");
    const [isAddingNewType, setIsAddingNewType] = useState(false);
    const [loading, setLoading] = useState({
        show: false,
        message: ''
    });
    const [acknowledgmentPopup, setAcknowledgmentPopup] = useState({
        show: false,
        status: null,
        message: null,
        icon: null
    })

    const employee_id = localStorage.getItem("employeeID");
    const company_id = companyID();
    const formType = assetModel.title === 'Add'

    useEffect(() => {
        axios
            .get(`${config.API_BASE_URL}asset-types`)
            .then((response) => {
                const types = response.data.data.map(item => ({
                    value: item.id,
                    label: item.name
                }))
                setAssetTypes(types);
                selectAssetType(types);
            })
            .catch((error) => {
                console.error("Error fetching asset types:", error);
            });
    }, [isAddModalOpen]);

    const selectAssetType = (types) => {
        if (assetDetails.selectedAssetType) {
            const asset = types.filter(item => item.value == assetDetails.selectedAssetType)
            setAssetDetails(prev => ({ ...prev, selectedAssetType: asset[0] }))
        }
    }

    const handleAddNewAssetType = () => {
        if (!newAssetType) return;

        setLoading(prev => ({ ...prev, show: true, message: `Adding ${newAssetType}` }));
        axios
            .post(`${config.API_BASE_URL}asset-types`, { name: newAssetType })
            .then((response) => {
                const types = [response.data.data].map(item => ({
                    value: item.id,
                    label: item.newAssetType
                }))
                setAcknowledgmentPopup({
                    show: true,
                    status: "success",
                    message: response.data.message,
                    icon: 'fa-regular fa-circle-check'
                });
                setAssetTypes((prevTypes) => [...prevTypes, ...types]);
                setAssetDetails(prev => ({ ...prev, selectedAssetType: types[0] }));
                setNewAssetType("");
                setIsAddingNewType(false);
            })
            .catch((error) => {
                console.error("/asset-types", error);
                setAcknowledgmentPopup({
                    show: true,
                    status: "danger",
                    message: "Something went wrong",
                    icon: 'fa-regular fa-circle-xmark'
                })
            })
            .finally(() => {
                setLoading(prev => ({ ...prev, show: false, message: '' }));
                hideAcknowledgmentPopup(false);
            })
    };

    const disableAddAssetButton = () => {
        const { selectedDate,
            selectedAssetType,
            serialNumber,
            modelNumber,
            manufacturer,
            warranty } = assetDetails

        return (selectedDate && selectedAssetType && serialNumber && modelNumber && manufacturer && warranty)
    }

    const formatDateForSQL = (date) => {
        return format(date, "yyyy-MM-dd");
      };

    const handleSubmit = () => {

        const assetData = {
            asset_type: assetDetails.selectedAssetType.value,
            serial_number: assetDetails.serialNumber,
            model: assetDetails.modelNumber,
            manufacturer: assetDetails.manufacturer,
            warranty: assetDetails.warranty,
            purchase_date: formatDateForSQL(assetDetails.selectedDate),
            created_by: employee_id,
            company_id: company_id
        };

        setLoading(prev => ({ ...prev, show: true, message: `${formType ? 'Adding' : 'Updating'} new asset` }));

        let url;

        if (formType) {
            url = `${config.API_BASE_URL}company-assets`
        } else {
            url = `${config.API_BASE_URL}company-assets/${assetDetails.editAssetId}`
        }

        (formType ? axios.post : axios.put)(url, assetData)
            .then((response) => {
                setAcknowledgmentPopup({
                    show: true,
                    status: "success",
                    message: response.data.message,
                    icon: 'fa-regular fa-circle-check'
                });
                setAssetDetails({ detail })
            })
            .catch((error) => {
                console.error("/company-assets:", error);
                setAcknowledgmentPopup({
                    show: true,
                    status: "danger",
                    message: "Something went wrong",
                    icon: 'fa-regular fa-circle-xmark'
                })
            })
            .finally(() => {
                setLoading(prev => ({ ...prev, show: false, message: '' }));
                hideAcknowledgmentPopup()
            })
    };

    const closeModel = () => {
        setIsAddModalOpen(false);
        setAssetDetails(detail);
        setAssetTypes([]);
        setIsAddingNewType(false);
        setNewAssetType('');
        setAssetModel({
            title: 'Add',
            buttonText: 'Add'
        })
    }

    const hideAcknowledgmentPopup = (closeModal = true) => {
        const resetAcknowledgmentPopupData = () => {
            setAcknowledgmentPopup({
                show: false,
                status: null,
                message: null,
                icon: null
            });
            if (closeModal) {
                closeModel();
            }
        }
        setTimeout(() => resetAcknowledgmentPopupData()
            , 2000);
    };

    return (
        <div id="add_asset" className={`modal custom-modal fade ${isAddModalOpen ? "show d-block" : ""}`}>
            <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{assetModel.title} Asset</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={closeModel}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <div className="d-flex justify-content-between align-items-baseline">
                                            <label className="col-form-label">
                                                Asset Type <span className="text-danger">*</span>
                                            </label>
                                            {!isAddingNewType ? <button
                                                className="btn btn-link"
                                                style={{ fontSize: '12px' }}
                                                onClick={() => setIsAddingNewType(true)}
                                            >
                                                <i className="fa-solid fa-circle-plus"></i> Add New
                                            </button> : <></>}
                                        </div>
                                        {isAddingNewType ? (
                                            <div className="d-flex">
                                                <input
                                                    type="text"
                                                    className="form-control me-2"
                                                    value={newAssetType}
                                                    onChange={(e) => setNewAssetType(e.target.value)}
                                                />
                                                <div className="d-flex align-items-center">
                                                    <button
                                                        disabled={newAssetType ? false : true}
                                                        className="btn btn-success badge me-2"
                                                        onClick={handleAddNewAssetType}
                                                        style={{ height: "fit-content" }}
                                                    >
                                                        Add
                                                    </button>
                                                    <button
                                                        className="btn btn-danger badge"
                                                        onClick={() => {
                                                            setIsAddingNewType(false)
                                                            setNewAssetType('')
                                                        }
                                                        }
                                                        style={{ height: "fit-content" }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <Select
                                                options={assetTypes}
                                                value={assetDetails.selectedAssetType}
                                                onChange={(selectedOption) => setAssetDetails(prev => ({ ...prev, selectedAssetType: selectedOption }))}
                                                placeholder='Select Asset Type'
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Serial Number <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={assetDetails.serialNumber}
                                            onChange={(e) => setAssetDetails(prev => ({ ...prev, serialNumber: e.target.value }))}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Model Number <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={assetDetails.modelNumber}
                                            onChange={(e) => setAssetDetails(prev => ({ ...prev, modelNumber: e.target.value }))}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Manufacturer <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={assetDetails.manufacturer}
                                            onChange={(e) => setAssetDetails(prev => ({ ...prev, manufacturer: e.target.value }))}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Warranty (months) <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={assetDetails.warranty}
                                            onChange={(e) => setAssetDetails(prev => ({ ...prev, warranty: e.target.value }))}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="input-block mb-3">
                                        <label className="col-form-label">
                                            Purchase Date <span className="text-danger">*</span>
                                        </label>
                                        <div className="cal-icon">
                                            <DatePicker
                                                className="form-control floating datetimepicker "
                                                selected={assetDetails.selectedDate}
                                                onChange={(date) => setAssetDetails(prev => ({ ...prev, selectedDate: date }))}
                                                dateFormat="dd-MM-yyyy"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="submit-section d-flex justify-content-end">
                                <button
                                    disabled={disableAddAssetButton() ? false : true}
                                    className="btn btn-primary submit-btn"
                                    onClick={handleSubmit}
                                >
                                    {assetModel.buttonText} Asset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {acknowledgmentPopup.show ? <AcknowledgmentPopup
                popupVariant={acknowledgmentPopup.status}
                popupMessage={acknowledgmentPopup.message}
                popupIcon={acknowledgmentPopup.icon} /> : <></>}
            {(acknowledgmentPopup.show) && <div className="modal-backdrop fade show"></div>}
            <Loader show={loading.show} loadingMessage={loading.message} />
        </div>
    );
};

export default AddAssetModel;
