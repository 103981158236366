/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Table } from "antd";
import SearchBox from "../../../../components/SearchBox";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import GoalTrackingModal from "../../../../components/modelpopup/GoalTrackingModal";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";
import ProtectedButton from "../../../../Routes/Approuter/ProtectedButton";
import axios from "axios";
import config from "../../../../config";
import { formatDateInTimezone, getCurrentDate } from "./../../../../utils/formatDateInTimezone";
import DatePicker from "react-datepicker";
import "../../../../assets/css/goalTracking.css";
import { Button } from "react-bootstrap";
import { companyID } from "../../../../auth";
import Stepper from "./Stepper";

const GoalTracking = () => {
  const navigate = useNavigate();
  const [goalData, setGoalData] = useState([]);
  const [goalDataError, setGoalDataError] = useState(null);
  const [paginationValue, setPaginationValue] = useState(5);
  const date = new Date();
  const current_year = date.getFullYear();
  const location = useLocation();

  const [selectedYear, setSelectedYear] = useState(current_year);
  const employee_id = localStorage.getItem("employeeID");

  const getAllMainGoalData = async () => {
    try {
      if (employee_id && selectedYear) {
        const response = await axios.get(
          `${config.API_BASE_URL}performance/get-main-goal/${employee_id}?year=${selectedYear}`
        );
        if (response?.data?.result?.length > 0) {
          setGoalData(response?.data?.result);
        } else {
          setGoalData([]);
          setGoalDataError("No Data Found");
        }
      }
    } catch (err) {
      console.log("No Data Found", err);
      setGoalDataError("No Data Found");
    }
  };
  useEffect(() => {
    getAllMainGoalData();
  }, [selectedYear]);

  const columns = [
    {
      title: "Goal",
      dataIndex: "main_goal",
      // sorter: (a, b) => a.main_goal.length - b.main_goal.length,
    },
    {
      title: "Weightage",
      dataIndex: "weightage",
      // sorter: (a, b) => a.weightage.length - b.weightage.length,
      render: (text) => <div className="">{text}%</div>,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      // sorter: (a, b) => a.start_date.length - b.start_date.length,
      render: (text, record) => <>{formatDateInTimezone(record.start_date)}</>,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      // sorter: (a, b) => a.end_date.length - b.end_date.length,
      render: (text, record) => <>{formatDateInTimezone(record.end_date)}</>,
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      render: (text, record) => <>{formatDateInTimezone(record.created_at)}</>,
      // sorter: (a, b) => formatDateInTimezone(a.created_at).length - formatDateInTimezone(b.created_at).length,
    },
    {
      title: "Status",
      dataIndex: "goal_status_name",
      render: (text) => (
        <div className="badge " style={{ backgroundColor: "#3A7D44" }}>
          {text}
        </div>
        // <div className="dropdown action-label">
        //   <Link
        //     className="btn btn-white btn-sm btn-rounded dropdown-toggle"
        //     to="#"
        //     data-bs-toggle="dropdown"
        //     aria-expanded="false"
        //   >
        //     <i
        //       className={
        //         text === "Inactive"
        //           ? "far fa-dot-circle text-danger"
        //           : "far fa-dot-circle text-success"
        //       }
        //     />{" "}
        //     {text}
        //   </Link>
        //   <div className="dropdown-menu">
        //     <Link className="dropdown-item" to="#">
        //       <i className="far fa-dot-circle text-success" /> Active
        //     </Link>
        //     <Link className="dropdown-item" to="#">
        //       <i className="far fa-dot-circle text-danger" /> Inactive
        //     </Link>
        //   </div>
        // </div>
      ),
      // sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Submission Status",
      dataIndex: "submission_status_name",
      render: (text , record) => (
        <div className=" " style={{ color: (record?.submission_status==1 || record?.submission_status==4) ? "red": record?.submission_status==2 ? "#f2be3a" : "#3A7D44" }}>
          {text}
        </div>
        
      ),
      // sorter: (a, b) => a.status.length - b.status.length,
    },
    // {
    //   title: "Progress",
    //   dataIndex: "progress",
    //   render: (text) => (
    //     <span>
    //       <p className="mb-1">Completed {text}%</p>
    //       <div className="progress" style={{ height: "5px" }}>
    //         <div
    //           className="progress-bar bg-primary progress-sm"
    //           style={{ width: "73%", height: "10px" }}
    //         />
    //       </div>
    //     </span>
    //   ),
    //   sorter: (a, b) => a.progress.length - b.progress.length,
    // },
    // {
    //   title: "Action",
    //   sorter: true,
    //   render: () => (
    //     <div className="dropdown dropdown-action text-end">
    //       <Link
    //         to="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="material-icons">more_vert</i>
    //       </Link>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit_goal"
    //         >
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </Link>
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete"
    //         >
    //           <i className="fa fa-trash m-r-5" /> Delete
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const handleNavigation = () => {
    const navigationState = goalData?.length > 0;

    const queryParams =
      goalData?.length > 0
        ? `employee_id=${employee_id}&year=${selectedYear}`
        : `employee_id=${employee_id}&year=${selectedYear}`;

    navigate(`../manage-goal${queryParams ? `?${queryParams}` : ""}`, {
      state: { hasGoals: navigationState, reviewer: false, back: "/goal-list", goal_review_deadline : null , isReviewed :null },
    });
  };

  const [selfGoalReviewCycle, setSelfGoalReviewCycle] = useState(null);
  const [goalReviewSubmission, setGoalReviewSubmission] = useState(null);
  const [goalEditSubmission, setGoalEditSubmission] = useState(null);
  const [performanceReview, setPerformanceReview] = useState(null);
  const [createGoal, setCreateGoal] = useState(null);

  const fetchConfiguration = async () => {
    const company_id = companyID();
    const response = await axios.get(
      `${config.API_BASE_URL}performance/goal-config?company_id=${company_id}`
    );
    if (response?.data?.result?.length > 0) {
      response?.data?.result.forEach((item) => {
        switch (item?.review_type) {
          case 1:
            setSelfGoalReviewCycle(item);
            break;
          case 2:
            setGoalReviewSubmission(item);
            break;
          case 3:
            setGoalEditSubmission(item);
            break;
          case 4:
            setPerformanceReview(item);
            break;
          case 5:
            setCreateGoal(item);
            break;
          default:
            break;
        }
      });
    }
  };

  useEffect(() => {
    fetchConfiguration();
  }, []);


 

  console.log(`"data"` , createGoal);
  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle={`Goal For ${selectedYear}`}
            title="Dashboard"
            subtitle="Goal"
            // modal="#add_goal"
            // name="Add New"
          />
          {/* /Page Header */}

          {/* --------------------- TODO------------------------- */}
          {/* <div className="row">
          <div className="col-md-12">
            <Stepper/>
            </div>
            </div> */}

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <div className="d-flex justify-content-between align-item-center">
                  <div className=" flex-grow-1">
                    <SearchBox setPaginationValue={setPaginationValue} />
                  </div>
                  <div className="custom-datepicker-wrapper flex-end flex-grow-1">
                    <DatePicker
                      selected={new Date(selectedYear, 0, 1)}
                      onChange={(date) => {
                        const year = date.getFullYear();
                        setSelectedYear(year);
                      }}
                      dateFormat="yyyy"
                      showYearPicker
                      showMonthDropdown={false}
                      showDayDropdown={false}
                      className="form-control custome_date_change"
                      minDate={new Date(2020, 0, 1)}
                      maxDate={new Date(current_year, 11, 31)}
                      id="year-picker"
                    />
                    {/* <i
        className="fa fa-calendar calendar-icon"
        onClick={() => document.getElementById("year-picker").click()}
      ></i> */}
                  </div>
                  <div className="d-flex justify-content-end ">
                    <ProtectedButton page="goal-list" action="create">
                      <button
                        onClick={() => handleNavigation()}
                        className=" btn btn-link"
                      >
                        {goalData.length > 0 ? (
                          <>
                            <i class="fa-solid fa-eye"></i> View Goal
                          </>
                        ) : (
                          (createGoal?.active == 1 &&  formatDateInTimezone(createGoal?.deadline) >= getCurrentDate()  )&& (
                            <>
                              {" "}
                              <i class="fa-solid fa-plus"></i>{" "}
                              <span>Create Goal</span>
                            </>
                          )
                        )}
                      </button>
                    </ProtectedButton>
                  </div>
                </div>
                
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={goalData.length > 0 && goalData}
                  pagination={{ pageSize: paginationValue }}
                  // rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <GoalTrackingModal />
      <DeleteModal Name="Delete Goal Tracking List" />
    </>
  );
};

export default GoalTracking;
