export function convertTimestampToDate(timestamp, timeZone = 'Asia/Kolkata') {
    const date = new Date(timestamp * 1000);
    return new Intl.DateTimeFormat('en-IN', {
      timeZone,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    }).format(date);
  }
  