import React, { useEffect, useState } from 'react';
import PlanSelection from './PlanSelection';
import PaymentStep from './PaymentStep';
import axios from 'axios';
import config from '../../../config';

const SubscriptionPage = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [showAlert, setShowAlert] = useState(false);
    const [plans, setPlans] = useState([]);

    useEffect(()=>{
        axios.get(`${config.API_BASE_URL}plans`)
        .then((res)=>{
         setPlans(res.data.data)
        })
        .catch((err)=>{
         console.log(err)
        })
        .finally(()=>{
         // laoder
        })
    },[]);

    const handlePayment = () => {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 2000);
        setSelectedPlan(null);
        setCurrentStep(1);
    };

    const isSubscriptionExpired = JSON.parse(localStorage.getItem("isSubscriptionExpired"));

    return (
        <>
            {currentStep === 1 && (
                <PlanSelection
                    plans={plans}
                    onSelectPlan={(plan) => {
                        setSelectedPlan(plan);
                        setCurrentStep(2);
                    }}
                    showAlert={showAlert}
                    isSubscriptionExpired={isSubscriptionExpired}
                />
            )}

            {currentStep === 2 && (
                <PaymentStep
                    selectedPlan={selectedPlan}
                    onBack={() => setCurrentStep(1)}
                    onPaymentSuccess={handlePayment}
                    isSubscriptionExpired={isSubscriptionExpired}
                />
            )}
        </>
    );
};

export default SubscriptionPage;
