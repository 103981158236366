import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import config from "../../../config";
import { getPropertyByKey } from "../../../utils/getPropertyByKey";
import { companyID } from "../../../auth";

const AssetDetails = ({ setShowCompanyAssets, setIsAddModalOpen, setAssetDetails, setAssetModel, isAddModalOpen }) => {

    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [assetId, setAssetId] = useState(null);
    const [assetModalLoading, setAssetModalLoading] = useState(true);
    const [assetData, setAssetData] = useState({});
    const [assetStatus, setAssetStatus] = useState([]);
    const [assetType, setAssetType] = useState([]);
    const [companyAssets, setCompanyAssets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    const employeeId = localStorage.getItem("employeeID");
    const company_id = companyID();

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        // setEmployId('');
    };

    //   const deleteSalary=()=>{
    //     setLoading(true);
    //     axios.put(`${config.API_BASE_URL}employee/salary-detail/${assetId}`,{
    //       updated_by: employeeId
    //     })
    //     .then(res=>{
    //       console.log(res.data.message);
    //       setAssetId(null);
    //     })
    //     .catch(err=>{
    //       console.log(err)
    //     })
    //     .finally(()=>{
    //       setLoading(false)
    //       // loader
    //     })
    //   }

    useEffect(() => {
        fetchAssetStatus();
        fetchAssetType();
    }, [isAddModalOpen])

    const fetchAssetStatus = () => {
        axios.get(`${config.API_BASE_URL}assets-status`)
            .then((res) => {
                setAssetStatus(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const fetchAssetType = () => {
        axios.get(`${config.API_BASE_URL}asset-types`)
            .then((res) => {
                setAssetType(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const fetchCompanyAssets = () => {
        axios.get(`${config.API_BASE_URL}company-assets/${company_id}`)
            .then((res) => {
                setCompanyAssets(res.data.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (company_id) {
            fetchCompanyAssets();
        }
    }, [isAddModalOpen])

    const editAssetDetail = (record) => {
        setAssetModel({
            title: 'Edit',
            buttonText: 'Update'
        })
        setIsAddModalOpen(true);
        const { purchase_date, model, serial_number, asset_type, manufacturer, warranty, id } = record
        setAssetDetails({
            selectedDate: purchase_date,
            selectedAssetType: asset_type,
            serialNumber: serial_number,
            modelNumber: model,
            manufacturer: manufacturer,
            warranty: warranty,
            editAssetId: id
        });
    }

    const columns = [
        {
            title: "Asset",
            dataIndex: "asset_type",
            key: 'asset_type',
            render: (text) => getPropertyByKey(assetType, 'id', text, 'name')
        },
        {
            title: "Serial number",
            dataIndex: "serial_number",
            key: 'serial_number',
            render: (text) => text || '-'
        },
        {
            title: "Model",
            dataIndex: "model",
            key: "model",
            render: (text) => text || '-'
        },
        {
            title: "Manufacturer",
            dataIndex: "manufacturer",
            key: "manufacturer",
            render: (text) => text || '-'
        },
        {
            title: "Purchase date",
            dataIndex: "purchase_date",
            key: "purchase_date",
            render: (text) => {
                if (text) {
                    return text.split('T')[0]
                } else {
                    return '-'
                }
            }
        },
        {
            title: "Warranty",
            dataIndex: "warranty",
            key: "warranty",
            render: (text) => text || '-'
        },
        {
            title: "Action",
            render: (text, record) => (
                <div className="action-menu text-end">
                    <div className="d-flex gap-2 mt-2">
                        <Link
                            className="dropdown-item"
                            onClick={(e) => {
                                e.preventDefault();
                                editAssetDetail(record)
                            }}
                        >
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-view" style={{ fontSize: "12px", padding: "2px" }}>
                                        Edit Asset Detail
                                    </Tooltip>
                                }
                            >
                                <i className="fa fa-pencil m-r-5 text-success" />
                            </OverlayTrigger>
                        </Link>
                    </div>
                </div>
            ),
        },
    ];
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <div className="d-flex justify-content-between">
                        <div className="flex-grow-1">
                            <SearchBox setPaginationValue={setPageSize} />
                            </div>
                            <button className="btn btn-text" onClick={() => setShowCompanyAssets(false)}><i className="fa-solid fa-arrow-left" /> Back</button>
                        </div>
                        <Table
                            className="table-striped"
                            columns={columns}
                            dataSource={companyAssets}
                            rowKey={(record) => record.id}
                            loading={loading}
                            pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                total: companyAssets.length,
                                showSizeChanger: true,
                                onChange: handlePageChange,
                                pageSizeOptions: ['5', '10', '15']
                            }}
                        />
                    </div>
                </div>
            </div>
            
        {/* <DeleteModal Name="Delete Salary" confirmDelete={deleteSalary}/>  */}

        </div>
    );
};

export default AssetDetails;
