import { click } from "@testing-library/user-event/dist/cjs/convenience/click.js";
import axios from "axios";
import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import config from "../../config";

const GoalActivationModal = ({
  show,
  handleClose,
  activationState,
  handleData,
  fetchConfiguration,
  goalConfigurationData
}) => {
  console.log("activationState", activationState, handleData);
  

  const handleSubmit = async(data) =>{
    const emp_id = localStorage.getItem('employeeID');
    const configData = { data , goalConfigurationData  }
     try{
     const response = await axios.post(`${config.API_BASE_URL}performance/update-goal-activation-status?employee_id=${emp_id}` ,configData );
     if(response?.data?.success == true){
        fetchConfiguration();
        handleClose();
     }
     }
     catch(err){
        console.log(err);
     }
  }
  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered
      onHide={handleClose}
    >
      <Modal.Header  style={{ borderBottom: "0px" }}>
        <div className="text-end w-100">
          <i
            class="fa-solid fa-xmark"
            onClick={handleClose}
            // style={{ marginRight: "-10px" }}
          ></i>
        </div>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center">
          {handleData?.active == 0
            ? `Are You Sure Want to Active the ${handleData?.name}?`
            : `Are You Sure Want to InActive the ${handleData?.name}?`}
        </h4>
        <div className="text-end mt-5">
          <Button
            style={{ marginRight: "10px" }}
            variant="secondary" // Changed 'muted' to 'secondary'
          >
            Close
          </Button>
          <Button
            variant={handleData?.active == 0 ? `success` : `danger`}
            onClick={()=>handleSubmit(handleData)} // Disable if total weightage exceeds 100
          >
            {handleData?.active == 0 ? `Active` : `InActive`}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GoalActivationModal;
