import React from 'react';
import { Card, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import AcknowledgmentPopup from '../../../utils/AcknowledgmentPopup';

const PlanSelection = ({ plans, onSelectPlan, showAlert, isSubscriptionExpired }) => {
    return (
        <div className={isSubscriptionExpired ? "mt-5 pt-5" : "page-wrapper"} style={{ position: 'relative', height: '100vh' }}>
        <Container className="p-4">
            <h2 className="text-center mb-4">Choose Your Plan</h2>
            <Row className="g-4 d-flex justify-content-center">
                {plans.map((plan) => (
                    <Col key={plan.plan_id} md={4}>
                        <Card>
                            <Card.Body>
                                <Card.Title style={{textAlign:'center'}}>{plan.plan_name}</Card.Title>
                                <Card.Text >
                                    <div className='d-flex justify-content-center align-items-center'>
                                    <ul>
                                    <div className='d-flex gap-2 align-items-baseline'>
                                    <i class="fa-regular fa-clock text-success"/>
                                    <li className='mb-2' key={plan.duration}>Duration: {plan.duration} Months</li>
                                        </div>
                                        {plan.features.map((feature, index) => (
                                            <div className='d-flex gap-2 align-items-center'>
                                                {feature.is_enabled ? <i className="fa fa-check text-success" /> : <i class="fa-solid fa-xmark"/>}
                                                <li key={index} className='mb-2'>{feature.feature_name}</li></div>
                                        ))}
                                    </ul>
                                    </div>
                                    <h4 style={{textAlign:'center'}}>₹ {plan.price.amount}</h4>
                                </Card.Text>
                                <div className='d-flex align-items-center justify-content-center'>
                                <Button
                                    style={{textAlign:'center'}}    
                                    variant="primary"
                                    onClick={() => onSelectPlan(plan)}
                                >
                                    Select Plan
                                </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
        {showAlert && (
                <AcknowledgmentPopup 
                popupVariant='success' 
                popupMessage='Payment Successful !' 
                popupIcon="fa-regular fa-circle-check" />
            )}
        </div>
    );
};

export default PlanSelection;
