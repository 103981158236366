import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import axios from 'axios';
import config from '../../../config';
import { companyID } from '../../../auth';
import LeaveCarryForward from '../../../components/modelpopup/LeaveCarryForward';
import Loader from '../../../components/Loader';
import AcknowledgmentPopup from '../../../utils/AcknowledgmentPopup';
import Breadcrumbs from '../../../components/Breadcrumbs';

const CompanyLeaves = () => {

    const company_id = companyID();
    const user = localStorage.getItem("user");
    let department_id;
    if (user) {
        const companyDetails = JSON.parse(user);
        department_id = companyDetails.department_id
    }

    const [CompanyLeaves, setCompanyLeaves] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [selectedLeave, setSelectedLeave] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState({
        show: false,
        message: ''
    })
    const [acknowledgmentPopup, setAcknowledgmentPopup] = useState({
        show: false,
        status: null,
        message: null,
        icon: null
    })

    useEffect(() => {
        if (company_id && department_id) {
            fetchCompanyLeaves();
            fetchDepartmentEmployees()
        }
    }, [company_id, department_id]);

    const fetchCompanyLeaves = () => {
        axios.get(`${config.API_BASE_URL}leave-types?company_id=${company_id}`)
            .then((res) => {
                setCompanyLeaves(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const fetchDepartmentEmployees = () => {
        axios.get(`${config.API_BASE_URL}employees/employeeDetails/${company_id}?department_id=${department_id}`)
            .then((res) => {
                setEmployees(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const columns = [
        {
            title: 'Leave Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Total Days',
            dataIndex: 'total_days',
            key: 'total_days',
        },
        {
            title: 'Allow leave forward',
            dataIndex: 'leave_forwarded',
            key: 'leave_forwarded',
        },
        {
            title: 'Carry Forward',
            key: 'carry_forward',
            render: (_, record) =>
                record.carry_forward ? (
                    <Button type="primary" size="small" onClick={() => handleCarryForward(record)}>
                        Carry Forward
                    </Button>
                ) : (
                    <span>No</span>
                ),
        },
    ];

    const handleCarryForward = (record) => {
        setSelectedLeave(record);
        setModalShow(true);
    };

    const hideAcknowledgmentPopup = () => {
        const resetAcknowledgmentPopupData = () => {
            setAcknowledgmentPopup({
                show: false,
                status: null,
                message: null,
                icon: null
            });
        }
        setTimeout(() => resetAcknowledgmentPopupData()
            , 2000);
    };

    const handleModalSubmit = (data) => {
        const currentYear = new Date().getFullYear();

        const carryForwardData = {
            "leave_id": selectedLeave.leave_id,
            "total_days": selectedLeave.total_days,
            "leave_forwarded": selectedLeave.leave_forwarded,
            "company_id": company_id,
            "employee_ids": data.selectedEmployees.map(employee => employee.value),
            "carry_forward_year": currentYear
        }
        setLoading({ message: 'Leave carry forwarding...', show: true })
        axios.post(`${config.API_BASE_URL}carry-forward-leaves`, carryForwardData)
            .then((res) => {

                const skippedEmployee = employees.filter(employee => res.data?.skippedEmployeeIds?.includes(employee.id))
                    .map(item => [item.first_name, item.last_name, item.middle_name].filter(Boolean).join(" "))
                    .join(", ")
                setAcknowledgmentPopup({ show: true, message: res.data.message + '. ' + (res.data?.skippedEmployeeIds?.length ? skippedEmployee + ' Already leaves carry forwarded' : ''), status: "success", icon: 'fa-regular fa-circle-check' })
            })
            .catch((err) => {
                console.log(err);
                setAcknowledgmentPopup({
                    show: true,
                    status: "danger",
                    message: "Something went wrong",
                    icon: 'fa-regular fa-circle-xmark'
                })
            })
            .finally(() => {
                setLoading({ message: '', show: false })
                hideAcknowledgmentPopup()
            })
    };

    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <Breadcrumbs
                    maintitle="Company Leaves"
                    title="Dashboard"
                    subtitle="Company Leaves"
                />
                <div className="container mt-4">
                    <Table
                        columns={columns}
                        dataSource={CompanyLeaves}
                        rowKey="id"
                        bordered
                        pagination={{ pageSize: 5 }}
                    />
                    {modalShow && <LeaveCarryForward
                        show={modalShow}
                        handleClose={() => setModalShow(false)}
                        handleSubmit={handleModalSubmit}
                        employees={employees}
                        selectedLeave={selectedLeave}
                    />}
                </div>
                {acknowledgmentPopup.show ? <AcknowledgmentPopup
                    popupVariant={acknowledgmentPopup.status}
                    popupMessage={acknowledgmentPopup.message}
                    popupIcon={acknowledgmentPopup.icon} /> : <></>}
                <Loader show={loading.show} loadingMessage={loading.message} />
            </div>
        </div>
    );
};

export default CompanyLeaves;
