import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config";
import { Button } from "react-bootstrap";
import Loader from "../../../components/Loader";
import { getStartandEndDate } from "../../../utils/getStartandEndDate";

const RazorpayButton = ({ amount, currency, receipt, redirectToGateway=false, setRedirectToGateway, paymentDetail, onPaymentSuccess }) => {

  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    if(redirectToGateway){
      handlePayment();
    }
  },[redirectToGateway])

  const handlePayment = async () => {
 
    setLoading(true);
    const subscriptionDate = getStartandEndDate(paymentDetail.duration);

    try {

      const orderResponse = await axios.post(`${config.API_BASE_URL}payments/create-order`, {
        amount,
        currency,
        receipt
      });

      const { order } = orderResponse.data;
      const { id: order_id, amount: order_amount, currency: order_currency } = order;

      const options = {
        key: 'rzp_test_GEQiM5Z2wZjEuy', 
        amount: order_amount, 
        currency: order_currency,
        name: "HR Globes",
        description: "Payment for subscription",
        order_id, 
        handler: async (response) => {
          const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response;

          const verifyResponse = await axios.post(`${config.API_BASE_URL}payments/verify-payment`, {
            razorpay_order_id,
            razorpay_payment_id,
            razorpay_signature,
            company_id: paymentDetail.company_id,
            plan_id: paymentDetail.plan_id,
            ...subscriptionDate,
            amount,
          });

          if (verifyResponse.data.success) {
            // alert("Payment successful!");
            onPaymentSuccess()
          } else {
            alert("Payment verification failed!");
          }
        },
        prefill: {
          name: "",
          email: "",
          contact: "",
          ...(paymentDetail.upi ? {method: "upi", vpa: paymentDetail.UPIid} : paymentDetail.card ? {method: "card"} : {method: "qr"}),
        },
        theme: {
          color: "#3399cc",
        },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();

      razorpay.on("payment.failed", (response) => {
        alert(`Payment failed: ${response.error.description}`);
      });
    } catch (error) {
      console.error("Error during payment process:", error);
      alert("Something went wrong while processing payment.");
    }
    finally{
     setLoading(false);
     setRedirectToGateway(false)
    }
  };

  return (
    <>
    <Button onClick={handlePayment} className="mt-2">
      Pay Now
    </Button>
    <Loader show={loading} loadingMessage="redirecting to payment gateway..." />
    </>
  );
};

export default RazorpayButton;
