import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios'; 
import config from "../../../config";
import * as Yup from "yup";
import createCustomSchema from "../../../fieldValidator/fieldValidator";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import login_icon from "../../../assets/img/login_icon.png";

const ForgotPassword = () => {

  const location = useLocation();
  let companyLogo, companyThemeColor, companyName;

  if (location.state) {
    ({ companyLogo, companyThemeColor, companyName } = location.state);
  }

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1); // Step 1: Enter email, Step 2: Enter OTP and new password
  const [message, setMessage] = useState("");
  const [emailError , setEmailError] = useState("");
  const [countdown, setCountdown] = useState(180);
  const [showResendOTP , setShowResendOTP] = useState(false);
  const [otpError , setOTPError] = useState("");
  const [resetSend , setResetSend] = useState(false);
  const [passwordError , setPasswordError] = useState("");
  const [paswworsFeildsError ,setPAsswordFeildsError] = useState({});
  const [newPasswordError , setNewPAssswordError] = useState("");
  const navigate = useNavigate();

  useEffect(()=>{
    const unVerifiedUser =   localStorage.getItem("invalidUser");
    setEmail(unVerifiedUser)
  },[])

  const validateEmail = async (email) => {
    
    try {
      const trimmedEmail = email.trim();
      const response = await axios.post(
        `${config.API_BASE_URL}auth/validate-email`,
        { email   :trimmedEmail }
      );
      if (response?.data?.exists == true){  
        return true;
      }
      else{
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  const handleEmailSubmit = async (e) => {
    // e.preventDefault();
    const trimmedEmail = email?.trim().toLowerCase();
    const isValid  = await validateEmail(trimmedEmail);
    if( isValid){
      try {
        const response = await axios.post(`${config.API_BASE_URL}auth/forgot-password`, { email :trimmedEmail });
        localStorage.setItem("OTP" , response?.data?.otp);
        setMessage(response.data.message);
        setEmailError("");
        setStep(2);
      } catch (error) {
        setMessage(error.response.data.message);
      }
    }else{
      setEmailError("Your Email is Incorrect , We Cannot Sent OTP To Your Email!")
    }
  };
  
  const handleResendOTP = async(email) =>{
    const trimmedEmail = email?.trim().toLowerCase();
    const isValid  = await validateEmail(trimmedEmail);
    if( isValid){
      try {
        const response = await axios.post(`${config.API_BASE_URL}auth/forgot-password`, { email :trimmedEmail });
        localStorage.setItem("OTP" , response?.data?.otp);
        setMessage(response.data.message);
        setEmailError("");
        setStep(2);
      } catch (error) {
        setMessage(error.response.data.message);
      }
    }else{
      setEmailError("Your Email is Incorrect , We Cannot Sent OTP To Your Email!")
    }
  }

  const handleResetPassword = async (e) => {
    if( !newPassword){
      setNewPAssswordError("Please enter password !");
    }
    if(!confirmPassword){
      setPasswordError("Please enter confirm password !")
      return;
    }   
    if( confirmPassword !== newPassword){
      setPasswordError("Password Doesn't Matched !")
      return;
    }
    try {
      const response = await axios.post(`${config.API_BASE_URL}auth/reset-password`, { email, otp, newPassword });
      setNewPAssswordError("");
      setPasswordError("")
      if(response?.data?.success  == true){
        setNewPassword("");
        setConfirmPassword("");
        toast("Password Changed Successfully")
        localStorage.removeItem("invalidUser");
        setTimeout(()=>{
          navigate("../");
        },1000)
      }

      setMessage(response?.data?.message);
      setEmail("");
      setOtp("");
      setNewPassword("");
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  useEffect(() => {
    let interval
    if(step === 2) {
     interval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(prevCountdown => prevCountdown - 1);
      } else {
        setStep(3);
        setShowResendOTP(true);
        localStorage.removeItem("OTP");
        clearInterval(interval);
        setResetSend(true);
      }
    }, 1000);
    }
    return () => clearInterval(interval);
  }, [countdown, step]);

  // const verifyOtpHandler = () =>{
  //   if(!otp){
  //     setotpErrorMessage("OTP Is required !");
  //     setCorrectOTP(false);
  //     return;
  //   }
  //   let storedOtp = localStorage.getItem("otp");

  //   if( storedOtp != otp){
  //     setotpErrorMessage("Incorrect OTP !");
  //     return ;
  //   }
  //   setCorrectOTP(true);
  //   setTimeout(() => {
  //     localStorage.removeItem("otp");
   
  //   }, 3 * 60 * 1000);
  // }

  const handleValidateOTP  = () =>{
    if(!otp){
      setOTPError("OTP is Required!");
      return;
    }
    const localotp = localStorage.getItem("OTP");
    if(!localotp){
      setOTPError("OTP is Expired ! Generate New OTP .");
      setStep(3);
      return;
    }
    if(localotp !== otp){
      setOTPError("Incorrect OTP!");
      return ;
    }
    setOTPError("");
    setStep(4);   
  }

  const handleFormSubmission = (e) =>{
    e.preventDefault();
    if( step === 1){
      handleEmailSubmit();
      setCountdown(180);
      return;
    }
    if( step === 2){
      handleValidateOTP();
      return;
    }
    if( step === 3){
      setOtp("");
      setOTPError("");
      handleEmailSubmit();
      setCountdown(180);
      return;
    }
    if( step === 4){
      handleResetPassword();
      return;
    }
  }

  return (
    <div className="account-page"  style={{backgroundColor: companyThemeColor || '', overflow: 'auto'}}>
      <div className="main-wrapper">
        <div className="account-content">
          <div className="container">
            <div className="account-logo">
              <Link to="#" onClick={(e)=> e.preventDefault()}>
                <img src={ !companyLogo.includes('undefined') ? companyLogo : login_icon} alt={companyName || 'app logo'} style={{ borderRadius: "50%", zIndex: "100", backgroundColor: "white", width: "150px", height: "150px", padding: '2px' }}/>
              </Link>
            </div>
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">Forgot Password?</h3>
                <p className="account-subtitle">
                  {step === 1 && "" }
                  {step === 2 && "OTP Sent to Your Email !"}
                </p>
                {/* {message && <p>{message}</p>} */}
                <form onSubmit={handleFormSubmission}>
                  {step === 1 && (
                    <div className="input-block">
                      <label>Email Address</label>
                      <input
                        className="form-control"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        autoFocus="true"
                        
                      />
                      {emailError && <p className="text-danger">*{emailError}</p>}
                    </div>
                  )}   
                    <>
                    {(step === 2 || step === 3) && (
                      <div className="input-block">
                        <label>OTP</label>
                        <input
                          className="form-control"
                          type="text"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          autoFocus="true"
                        />
                        {otpError && <p className="text-danger">*{otpError}</p>}
                        <p>{countdown > 0 && `Resend OTP in ${Math.floor(countdown / 60)}:${(countdown % 60).toString().padStart(2, '0')}`}</p>
                      </div>
                    )}
                     {
                      step === 4 && (
                        <>
                         <div className="input-block">
                        <label>New Password</label>
                        <input
                          className="form-control"
                          type="password"
                          value={newPassword}
                          onChange={async(e) => {setNewPassword(e.target.value)}}
                          autoFocus="true"
                        />
                        {newPasswordError && <p className="text-danger">{newPasswordError}</p>}
                      </div>
                      <div className="input-block">
                        <label>Confirm New Password</label>
                        <input
                          className="form-control"
                          type="password"
                          value={confirmPassword}
                          onChange={async(e) => {setConfirmPassword(e.target.value)
      
                          }}
                        
                          
                        />
                      {passwordError && <p className="text-danger">{passwordError}</p>}
                       
                      </div>
                        </>
                      )
                     }
                    </>
                  
                  <div className="input-block text-center">
                    <button className="btn btn-primary account-btn" type="submit">
                      {step === 1 && "Get OTP" }
                      {step === 2 && "Validate OTP"}
                      {step === 3 && "Resend OTP"}
                      {step === 4 && "Set Password"}
                    </button>
                  </div>
                  <div className="account-footer">
                    <p>
                      Remember your password? <Link to="/">Login</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
