import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import axios from "axios";
import config from "../../../../config";
import { Link, useNavigate } from "react-router-dom";
import User from "../../../../assets/img/icons/user3.png";
import DatePicker from "react-datepicker";
import SearchBox from "../../../../components/SearchBox";
import { Pagination, Table } from "antd";
import { formatDateInTimezone } from "../../../../utils/formatDateInTimezone";

function PerformanceReview() {
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [gaolReviewData, setGoalReviewData] = useState([]);
  const [paginationValue, setPaginationValue] = useState(5);
  const [selfReviewData, setSelfReviewData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPaginationValue(pageSize);
  };
  const getAllGoalPerformance = async (selectedYear) => {
    try {
      const employee_id = localStorage.getItem("employeeID");
      const response = await axios.get(
        `${config.API_BASE_URL}performance/reportees/goals-performance?year=${selectedYear}&employee_id=${employee_id}`
      );
      console.log("responsegetAllGoalPerformance", response);
      if (response?.data?.result?.length > 0) {
        setGoalReviewData(response?.data?.result);
        console.log("response", response);
        const selfreview =
          response?.data?.result[0]?.goals[0]?.contribute_by.filter(
            (item) => item?.reviewed_by == employee_id
          );
        setSelfReviewData(selfreview[0]?.goal_review_updated_at);
      } else {
        setGoalReviewData([]);
      }
    } catch (err) {
      console.log("err , in employee performnace revciew", err);
    }
  };
  useEffect(() => {
    getAllGoalPerformance(selectedYear);
  }, [selectedYear]);

  const handleNavigation = (employee_id, year, goals, deadline, isReviewed ,goal_creation_review , reportee_submission_status) => {
    console.log("deadline", deadline, employee_id, gaolReviewData, isReviewed);
    const navigationState = goals?.length > 0;

    const queryParams =
      goals?.length > 0
        ? `employee_id=${employee_id}&year=${year}`
        : `employee_id=${employee_id}&year=${year}`;

    navigate(`../manage-goal${queryParams ? `?${queryParams}` : ""}`, {
      state: {
        hasGoals: navigationState,
        reviewer: true,
        back: "/performance-review",
        goal_review_deadline: deadline,
        isReviewed: isReviewed,
        goal_creation_review:goal_creation_review,
        reportee_submission_status:reportee_submission_status
      },
    });
  };
  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Performance Review"
            title="Dashboard"
            subtitle="Performance Review"
          />
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex justify-content-between align-item-center">
                <div className=" flex-grow-1">
                  <SearchBox setPaginationValue={setPaginationValue} />
                </div>
                <div className="custom-datepicker-wrapper">
                  <DatePicker
                    selected={new Date(selectedYear, 0, 1)}
                    onChange={(date) => {
                      const year = date.getFullYear();
                      setSelectedYear(year);
                    }}
                    dateFormat="yyyy"
                    showYearPicker
                    showMonthDropdown={false}
                    showDayDropdown={false}
                    className="form-control custome_date_change"
                    minDate={new Date(2020, 0, 1)}
                    maxDate={new Date(new Date().getFullYear(), 11, 31)}
                    id="year-picker"
                  />
                  {/* <i
        className="fa fa-calendar calendar-icon"
        onClick={() => document.getElementById("year-picker").click()}
      ></i> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {gaolReviewData?.length > 0 ? (
              gaolReviewData?.map((employee) => (
                <div
                  className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                  key={employee?.employee_id}
                  onClick={() =>
                    handleNavigation(
                      employee?.employee_id,
                      selectedYear,
                      employee?.goals,
                      employee?.deadline,
                      employee?.isReviewed,
                      employee?.goal_creation_review,
                      employee?.submission_status_id 
                    )
                  }
                >
                  <div className="profile-widget">
                    <div className="profile-img">
                      <img
                        src={
                          employee?.employee_profile
                            ? `${config.IMAGE_URL}${employee?.employee_profile}`
                            : User
                        }
                        alt=""
                      />
                    </div>
                    <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                      {/* <Link to={`profile/${employee?.employee_id}`}> */}
                      {employee?.employee_name}
                      {/* </Link> */}
                    </h4>
                    {/* <div className="small text-muted">
                    {employee?.designation_name
                      ? employee?.designation_name
                      : "-"}
                  </div> */}

                { employee?.goal_creation_review == 0 && <div
                      className={` ${
                        employee?.submission_status_id != 3 
                          ? "text-danger"
                          :"text-success"
                      }`}
                    >
                      <span>
                        {" "}
                        {(employee?.submission_status_name)}
                      </span>
                    </div>}
                    <div
                      className={` ${
                        employee?.goal_creation_review == 1 &&
                        employee?.isReviewed == 0
                          ? "text-danger"
                          : employee?.goal_creation_review == 1 &&
                            employee?.isReviewed == 1
                          ? "text-success"
                          : ""
                      }`}
                    >
                      <span>
                        {" "}
                        {(employee?.goal_creation_review == 1 &&
                          employee?.isReviewed == 0) 
                          ? "Awaiting Performance Review"
                          : employee?.goal_creation_review == 1 &&
                            employee?.isReviewed == 1
                          ? ""
                          : ""}
                      </span>
                    </div>

                    { employee?.deadline && (
                   ( employee?.isReviewed == 0 && employee?.deadline) ?   <div className="d-flex justify-content-between text-danger" >
                    <span> Deadline</span>
                    <span> {employee?.deadline && formatDateInTimezone(employee?.deadline)}</span>
                  </div> : 
                  <div className="d-flex justify-content-between text-success" >
                    <span> Review Date</span>
                    <span> {selfReviewData && formatDateInTimezone(selfReviewData)}</span>
                  </div>)
                  }
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12  mt-5">
                <h1 className="text-center " style={{ color: "#dedcdc" }}>
                  {" "}
                  No Data
                </h1>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end mb-4">
            <Pagination
              current={currentPage}
              pageSize={paginationValue}
              total={gaolReviewData?.length}
              // showSizeChanger
              onChange={handlePageChange}
              // pageSizeOptions={['5', '10', '15', '20']}
            />
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
}

export default PerformanceReview;
