import axios from 'axios';
import config from '../config';
import { convertTimestampToDate } from '../utils/convertTimestampToDate';

export const fetchCompanySubscription = async (companyId) => {
  try {
    const response = await axios.get(`${config.API_BASE_URL}subscriptions/company`, {
      params: { company_id: companyId },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching subscription:', error);
    throw error;
  }
};

export const checkSubscriptionExpiry = (subscription) => {
  if (!subscription.length || !subscription[0].end_date
  ) {
    throw new Error('Invalid subscription data');
  }

  const currentDate = Math.floor(Date.now() / 1000);
  const expiryDate = convertTimestampToDate(subscription[0].end_date);
  return expiryDate < convertTimestampToDate(currentDate);
};
